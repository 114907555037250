/* FORMS */
.form-horizontal {
  &.form-left {
    .form-group {
      & > label,
      & > .form-control-label,
      & > form-control-label {
        text-align: left;
        flex: 0 0 180px;
      }
    }
  }

  .form-group {
    display: flex;
    align-items: center;
    gap: 0.5rem;

    & > * {
      flex: 1;
      padding-left: 0;
    }

    & > label {
      flex: 0 0 200px;
    }

    label {
      font-weight: 700;
      text-align: right;
      caret-color: transparent;
    }

    .custom-control {
      display: flex;
      align-items: center;
      min-height: var(--fp_form_control_height, calc(2px + 2.25em));

      .fill-checkbox {
        display: flex;
        align-items: center;

        .fill-control-input {
          display: none;
        }

        .fill-control-indicator {
          border-radius: 3px;
          display: inline-block;
          position: relative;
          top: 4px;
          left: 0;
          width: 22px;
          height: 22px;
          border: 1px solid #ccc;
          -webkit-transition: 0.3s;
          transition: 0.3s;
          background: transparent;
          background-size: 0%;
          background-position: center;
          background-repeat: no-repeat;
          background-color: unset;
          background-image: url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='%23fff' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E");

          &:hover {
            cursor: pointer;
          }
        }

        .fill-control-input[checked="true"] ~ .fill-control-indicator,
        .fill-control-input:checked x ~ .fill-control-indicator {
          background-color: #26a69a;
          border-color: #26a69a;
          background-size: 70%;
        }

        .fill-control-input[checked="false"] ~ .fill-control-indicator {
          background-color: #e9ecef;
          border-color: #cdcdcd;
          background-image: unset;
        }
      }
    }
  }
}

.form-group .input-group-addon {
  height: var(--fp_form_control_height, calc(2px + 2.25em));
  display: flex;
  align-items: center;
}

.fp-form-cb-group {
  display: flex;
  align-items: center;

  label {
    margin: 0;
    flex: 0 0 170px;
  }
}

.form-control,
.single-line {
  height: var(--fp_form_control_height, calc(2px + 2.25em));
  background-color: #ffffff;
  background-image: none;
  border: 1px solid var(--fp_form_control_border_color, #e5e5e5);
  border-radius: var(--fp_main_border_radius, 4px);
  color: inherit;
  display: block;
  padding: 6px 12px;
  -webkit-transition: border-color 0.15s ease-in-out 0s,
    -webkit-box-shadow 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s,
    -webkit-box-shadow 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s;
  transition: border-color 0.15s ease-in-out 0s, box-shadow 0.15s ease-in-out 0s,
    -webkit-box-shadow 0.15s ease-in-out 0s;
  width: 100%;
  font-size: 14px;
}

.form-control:focus:not(.ng-invalid),
.single-line:focus {
  border-color: var(--fp_main_color) !important;
}

.has-success .form-control {
  border-color: var(--fp_main_color);
}

.cb-disabled {
  background-color: #e9ecef !important;
  border-color: #cdcdcd !important;
  opacity: 0.8;
}

.input-group-addon {
  background-color: #f8f9fa;
  border: 1px solid #e5e6e7;
  border-radius: var(--fp_main_border_radius, 6px);
  color: inherit;
  font-size: 14px;
  font-weight: 700;
  line-height: 1;
  padding: 0.5rem 1rem;
  text-align: center;
  height: calc(2px + 2.25em);
}

.input-group.currency {
  .input-group-addon {
    width: 63px;
    margin: 0;
    text-align: left;
    padding: 0 0 0 7px;
    color: #333333;
  }
}

.input-group.symbol {
  .input-group-addon {
    width: 45px;
    margin: 0;
    justify-content: center;
    padding: 0;
    color: #333333;
  }
}

.form-control.error {
  border: 1px dotted #cc5965;
}

.grid-container {
  display: grid;
  grid-template-columns: minmax(130px, auto) 1fr;
  grid-row-gap: 5px;
}

.rule-wrapper {
  .form-group {
    padding-left: 12px;
  }
}

.fp-rule-overview,
.fp-rule-override-wrapper {
  & > .form-group {
    padding-left: 12px;
  }

  .form-group {
    &:hover {
    }

    & > * {
      flex: 1;
      padding-left: 0;
    }

    & > label {
      flex: 0 0 230px;
      text-align: left;
      transition: all ease-in-out 0.1s;
    }
  }
}

.fp-rule-override-wrapper {
  .rule-wrapper,
  .driver-wrapper {
    padding-left: 28px;
  }
}

@media screen and (min-width: 1300px) {
  form[name="detailsForm"] .form-group *:nth-child(2) {
    max-width: 500px;
  }
}

@media screen and (max-width: 375px) {
  .form-horizontal .form-group label {
    text-align: left !important;
  }
}

@media screen and (max-width: 767px) {
  .form-horizontal {
    padding: 0 !important;

    .form-group {
      gap: 0;

      &>label {
        flex: 0 0 110px;
        line-height: 1.1;
      }
    }
  }
}

.bundle-price-wrapper {
  --fp_flex_form_label: 180px;

  .description-action {
    display: flex;
    align-items: center;
    gap: 3px;
    padding: 0 5px;

    label {
      line-height: 1;
    }

    a {
      i {
        font-size: 16pt;
      }
    }
  }

  .form-horizontal.form-left .form-group > label {
    flex: 0 0 var(--fp_flex_form_label);
  }

  .labeled-section {
    border: 1px dashed #dadada;
    border-radius: 6px;
    padding: 5px 10px;
    margin: 10px calc(var(--fp_flex_form_label) - 0px);
    margin-right: 0;

    & > small {
      position: relative;
      top: -16px;
      left: -5px;
      padding: 0 5px;
      background: white;
    }

    > :nth-child(2) {
      margin-top: -15px;
    }
  }
}

form-control-label,
.form-control-label {
  .help-icon {
    height: 14px;
    width: 14px;
    border: 1px solid #d1d1d1;
    border-radius: 50%;
    cursor: pointer;
  }

  .control-label {
    display: flex;
    align-items: center;
    gap: 4px;
  }
}

.step-properties {
  .form-horizontal.form-left .form-group > .form-control-label,
  .form-horizontal.form-left .form-group > form-control-label {
    flex: 0 0 92px;
  }

  .labeled-section {
    .form-group > .form-control-label,
    .form-group > form-control-label {
      flex: 0 0 200px;
    }
  }
}

.labeled-wrapper,
.step-properties {
  .labeled-section {
    border: 1px dashed #dadada;
    border-radius: 6px;
    padding: 5px 10px;
    margin: 10px 100px 0;
    margin-right: 0;

    & > small {
      position: relative;
      top: -16px;
      left: -5px;
      padding: 0 5px;
      background: white;
    }

    > :nth-child(2) {
      margin-top: -15px;
    }
  }
}
