.flag-icon-background {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
}

.flag-icon {
    background-size: contain;
    background-position: 50%;
    background-repeat: no-repeat;
    position: relative;
    display: inline-block;
    width: 1.33333333em;
    line-height: 1em;
}

.flag-icon:before {
    content: "\00a0";
}

.flag-icon-table {
    background-size: 40px;
    background-repeat: no-repeat;
    background-position-x: 20px;
    background-position-y: 50%;
}

.flag-icon-input {
    background-size: 7%;
    background-repeat: no-repeat;
    background-position-x: 95%;
    background-position-y: 50%;
}

.flag-icon-btn {
    width: 31px;
    height: 24px;
    background-repeat: no-repeat;
    background-size: contain;
    border: 1px solid #efefef;
    border-radius: 6px;
}

.flag-icon.flag-icon-squared {
    width: 1em;
}

.flag-icon-ad {
    background-image: url(/assets/img/icons/flags/ad.svg);
}

.flag-icon-ae {
    background-image: url(/assets/img/icons/flags/ae.svg);
}

.flag-icon-af {
    background-image: url(/assets/img/icons/flags/af.svg);
}

.flag-icon-ag {
    background-image: url(/assets/img/icons/flags/ag.svg);
}

.flag-icon-ai {
    background-image: url(/assets/img/icons/flags/ai.svg);
}

.flag-icon-al {
    background-image: url(/assets/img/icons/flags/al.svg);
}

.flag-icon-am {
    background-image: url(/assets/img/icons/flags/am.svg);
}

.flag-icon-ao {
    background-image: url(/assets/img/icons/flags/ao.svg);
}

.flag-icon-aq {
    background-image: url(/assets/img/icons/flags/aq.svg);
}

.flag-icon-ar {
    background-image: url(/assets/img/icons/flags/ar.svg);
}

.flag-icon-as {
    background-image: url(/assets/img/icons/flags/as.svg);
}

.flag-icon-at {
    background-image: url(/assets/img/icons/flags/at.svg);
}

.flag-icon-au {
    background-image: url(/assets/img/icons/flags/au.svg);
}

.flag-icon-aw {
    background-image: url(/assets/img/icons/flags/aw.svg);
}

.flag-icon-ax {
    background-image: url(/assets/img/icons/flags/ax.svg);
}

.flag-icon-az {
    background-image: url(/assets/img/icons/flags/az.svg);
}

.flag-icon-ba {
    background-image: url(/assets/img/icons/flags/ba.svg);
}

.flag-icon-bb {
    background-image: url(/assets/img/icons/flags/bb.svg);
}

.flag-icon-bd {
    background-image: url(/assets/img/icons/flags/bd.svg);
}

.flag-icon-be {
    background-image: url(/assets/img/icons/flags/be.svg);
}

.flag-icon-bf {
    background-image: url(/assets/img/icons/flags/bf.svg);
}

.flag-icon-bg {
    background-image: url(/assets/img/icons/flags/bg.svg);
}

.flag-icon-bh {
    background-image: url(/assets/img/icons/flags/bh.svg);
}

.flag-icon-bi {
    background-image: url(/assets/img/icons/flags/bi.svg);
}

.flag-icon-bj {
    background-image: url(/assets/img/icons/flags/bj.svg);
}

.flag-icon-bl {
    background-image: url(/assets/img/icons/flags/bl.svg);
}

.flag-icon-bm {
    background-image: url(/assets/img/icons/flags/bm.svg);
}

.flag-icon-bn {
    background-image: url(/assets/img/icons/flags/bn.svg);
}

.flag-icon-bo {
    background-image: url(/assets/img/icons/flags/bo.svg);
}

.flag-icon-bq {
    background-image: url(/assets/img/icons/flags/bq.svg);
}

.flag-icon-br {
    background-image: url(/assets/img/icons/flags/br.svg);
}

.flag-icon-bs {
    background-image: url(/assets/img/icons/flags/bs.svg);
}

.flag-icon-bt {
    background-image: url(/assets/img/icons/flags/bt.svg);
}

.flag-icon-bv {
    background-image: url(/assets/img/icons/flags/bv.svg);
}

.flag-icon-bw {
    background-image: url(/assets/img/icons/flags/bw.svg);
}

.flag-icon-by {
    background-image: url(/assets/img/icons/flags/by.svg);
}

.flag-icon-bz {
    background-image: url(/assets/img/icons/flags/bz.svg);
}

.flag-icon-ca {
    background-image: url(/assets/img/icons/flags/ca.svg);
}

.flag-icon-cc {
    background-image: url(/assets/img/icons/flags/cc.svg);
}

.flag-icon-cd {
    background-image: url(/assets/img/icons/flags/cd.svg);
}

.flag-icon-cf {
    background-image: url(/assets/img/icons/flags/cf.svg);
}

.flag-icon-cg {
    background-image: url(/assets/img/icons/flags/cg.svg);
}

.flag-icon-ch {
    background-image: url(/assets/img/icons/flags/ch.svg);
}

.flag-icon-ci {
    background-image: url(/assets/img/icons/flags/ci.svg);
}

.flag-icon-ck {
    background-image: url(/assets/img/icons/flags/ck.svg);
}

.flag-icon-cl {
    background-image: url(/assets/img/icons/flags/cl.svg);
}

.flag-icon-cm {
    background-image: url(/assets/img/icons/flags/cm.svg);
}

.flag-icon-cn {
    background-image: url(/assets/img/icons/flags/cn.svg);
}

.flag-icon-co {
    background-image: url(/assets/img/icons/flags/co.svg);
}

.flag-icon-cr {
    background-image: url(/assets/img/icons/flags/cr.svg);
}

.flag-icon-cu {
    background-image: url(/assets/img/icons/flags/cu.svg);
}

.flag-icon-cv {
    background-image: url(/assets/img/icons/flags/cv.svg);
}

.flag-icon-cw {
    background-image: url(/assets/img/icons/flags/cw.svg);
}

.flag-icon-cx {
    background-image: url(/assets/img/icons/flags/cx.svg);
}

.flag-icon-cy {
    background-image: url(/assets/img/icons/flags/cy.svg);
}

.flag-icon-cz {
    background-image: url(/assets/img/icons/flags/cz.svg);
}

.flag-icon-de {
    background-image: url(/assets/img/icons/flags/de.svg);
}

.flag-icon-dj {
    background-image: url(/assets/img/icons/flags/dj.svg);
}

.flag-icon-dk {
    background-image: url(/assets/img/icons/flags/dk.svg);
}

.flag-icon-dm {
    background-image: url(/assets/img/icons/flags/dm.svg);
}

.flag-icon-do {
    background-image: url(/assets/img/icons/flags/do.svg);
}

.flag-icon-dz {
    background-image: url(/assets/img/icons/flags/dz.svg);
}

.flag-icon-ec {
    background-image: url(/assets/img/icons/flags/ec.svg);
}

.flag-icon-ee {
    background-image: url(/assets/img/icons/flags/ee.svg);
}

.flag-icon-eg {
    background-image: url(/assets/img/icons/flags/eg.svg);
}

.flag-icon-eh {
    background-image: url(/assets/img/icons/flags/eh.svg);
}

.flag-icon-er {
    background-image: url(/assets/img/icons/flags/er.svg);
}

.flag-icon-es {
    background-image: url(/assets/img/icons/flags/es.svg);
}

.flag-icon-et {
    background-image: url(/assets/img/icons/flags/et.svg);
}

.flag-icon-fi {
    background-image: url(/assets/img/icons/flags/fi.svg);
}

.flag-icon-fj {
    background-image: url(/assets/img/icons/flags/fj.svg);
}

.flag-icon-fk {
    background-image: url(/assets/img/icons/flags/fk.svg);
}

.flag-icon-fm {
    background-image: url(/assets/img/icons/flags/fm.svg);
}

.flag-icon-fo {
    background-image: url(/assets/img/icons/flags/fo.svg);
}

.flag-icon-fr {
    background-image: url(/assets/img/icons/flags/fr.svg);
}

.flag-icon-ga {
    background-image: url(/assets/img/icons/flags/ga.svg);
}

.flag-icon-gb {
    background-image: url(/assets/img/icons/flags/gb.svg);
}

.flag-icon-gd {
    background-image: url(/assets/img/icons/flags/gd.svg);
}

.flag-icon-ge {
    background-image: url(/assets/img/icons/flags/ge.svg);
}

.flag-icon-gf {
    background-image: url(/assets/img/icons/flags/gf.svg);
}

.flag-icon-gg {
    background-image: url(/assets/img/icons/flags/gg.svg);
}

.flag-icon-gh {
    background-image: url(/assets/img/icons/flags/gh.svg);
}

.flag-icon-gi {
    background-image: url(/assets/img/icons/flags/gi.svg);
}

.flag-icon-gl {
    background-image: url(/assets/img/icons/flags/gl.svg);
}

.flag-icon-gm {
    background-image: url(/assets/img/icons/flags/gm.svg);
}

.flag-icon-gn {
    background-image: url(/assets/img/icons/flags/gn.svg);
}

.flag-icon-gp {
    background-image: url(/assets/img/icons/flags/gp.svg);
}

.flag-icon-gq {
    background-image: url(/assets/img/icons/flags/gq.svg);
}

.flag-icon-gr {
    background-image: url(/assets/img/icons/flags/gr.svg);
}

.flag-icon-gs {
    background-image: url(/assets/img/icons/flags/gs.svg);
}

.flag-icon-gt {
    background-image: url(/assets/img/icons/flags/gt.svg);
}

.flag-icon-gu {
    background-image: url(/assets/img/icons/flags/gu.svg);
}

.flag-icon-gw {
    background-image: url(/assets/img/icons/flags/gw.svg);
}

.flag-icon-gy {
    background-image: url(/assets/img/icons/flags/gy.svg);
}

.flag-icon-hk {
    background-image: url(/assets/img/icons/flags/hk.svg);
}

.flag-icon-hm {
    background-image: url(/assets/img/icons/flags/hm.svg);
}

.flag-icon-hn {
    background-image: url(/assets/img/icons/flags/hn.svg);
}

.flag-icon-hr {
    background-image: url(/assets/img/icons/flags/hr.svg);
}

.flag-icon-ht {
    background-image: url(/assets/img/icons/flags/ht.svg);
}

.flag-icon-hu {
    background-image: url(/assets/img/icons/flags/hu.svg);
}

.flag-icon-id {
    background-image: url(/assets/img/icons/flags/id.svg);
}

.flag-icon-ie {
    background-image: url(/assets/img/icons/flags/ie.svg);
}

.flag-icon-il {
    background-image: url(/assets/img/icons/flags/il.svg);
}

.flag-icon-im {
    background-image: url(/assets/img/icons/flags/im.svg);
}

.flag-icon-in {
    background-image: url(/assets/img/icons/flags/in.svg);
}

.flag-icon-io {
    background-image: url(/assets/img/icons/flags/io.svg);
}

.flag-icon-iq {
    background-image: url(/assets/img/icons/flags/iq.svg);
}

.flag-icon-ir {
    background-image: url(/assets/img/icons/flags/ir.svg);
}

.flag-icon-is {
    background-image: url(/assets/img/icons/flags/is.svg);
}

.flag-icon-it {
    background-image: url(/assets/img/icons/flags/it.svg);
}

.flag-icon-je {
    background-image: url(/assets/img/icons/flags/je.svg);
}

.flag-icon-jm {
    background-image: url(/assets/img/icons/flags/jm.svg);
}

.flag-icon-jo {
    background-image: url(/assets/img/icons/flags/jo.svg);
}

.flag-icon-jp {
    background-image: url(/assets/img/icons/flags/jp.svg);
}

.flag-icon-ke {
    background-image: url(/assets/img/icons/flags/ke.svg);
}

.flag-icon-kg {
    background-image: url(/assets/img/icons/flags/kg.svg);
}

.flag-icon-kh {
    background-image: url(/assets/img/icons/flags/kh.svg);
}

.flag-icon-ki {
    background-image: url(/assets/img/icons/flags/ki.svg);
}

.flag-icon-km {
    background-image: url(/assets/img/icons/flags/km.svg);
}

.flag-icon-kn {
    background-image: url(/assets/img/icons/flags/kn.svg);
}

.flag-icon-kp {
    background-image: url(/assets/img/icons/flags/kp.svg);
}

.flag-icon-kr {
    background-image: url(/assets/img/icons/flags/kr.svg);
}

.flag-icon-kw {
    background-image: url(/assets/img/icons/flags/kw.svg);
}

.flag-icon-ky {
    background-image: url(/assets/img/icons/flags/ky.svg);
}

.flag-icon-kz {
    background-image: url(/assets/img/icons/flags/kz.svg);
}

.flag-icon-la {
    background-image: url(/assets/img/icons/flags/la.svg);
}

.flag-icon-lb {
    background-image: url(/assets/img/icons/flags/lb.svg);
}

.flag-icon-lc {
    background-image: url(/assets/img/icons/flags/lc.svg);
}

.flag-icon-li {
    background-image: url(/assets/img/icons/flags/li.svg);
}

.flag-icon-lk {
    background-image: url(/assets/img/icons/flags/lk.svg);
}

.flag-icon-lr {
    background-image: url(/assets/img/icons/flags/lr.svg);
}

.flag-icon-ls {
    background-image: url(/assets/img/icons/flags/ls.svg);
}

.flag-icon-lt {
    background-image: url(/assets/img/icons/flags/lt.svg);
}

.flag-icon-lu {
    background-image: url(/assets/img/icons/flags/lu.svg);
}

.flag-icon-lv {
    background-image: url(/assets/img/icons/flags/lv.svg);
}

.flag-icon-ly {
    background-image: url(/assets/img/icons/flags/ly.svg);
}

.flag-icon-ma {
    background-image: url(/assets/img/icons/flags/ma.svg);
}

.flag-icon-mc {
    background-image: url(/assets/img/icons/flags/mc.svg);
}

.flag-icon-md {
    background-image: url(/assets/img/icons/flags/md.svg);
}

.flag-icon-me {
    background-image: url(/assets/img/icons/flags/me.svg);
}

.flag-icon-mf {
    background-image: url(/assets/img/icons/flags/mf.svg);
}

.flag-icon-mg {
    background-image: url(/assets/img/icons/flags/mg.svg);
}

.flag-icon-mh {
    background-image: url(/assets/img/icons/flags/mh.svg);
}

.flag-icon-mk {
    background-image: url(/assets/img/icons/flags/mk.svg);
}

.flag-icon-ml {
    background-image: url(/assets/img/icons/flags/ml.svg);
}

.flag-icon-mm {
    background-image: url(/assets/img/icons/flags/mm.svg);
}

.flag-icon-mn {
    background-image: url(/assets/img/icons/flags/mn.svg);
}

.flag-icon-mo {
    background-image: url(/assets/img/icons/flags/mo.svg);
}

.flag-icon-mp {
    background-image: url(/assets/img/icons/flags/mp.svg);
}

.flag-icon-mq {
    background-image: url(/assets/img/icons/flags/mq.svg);
}

.flag-icon-mr {
    background-image: url(/assets/img/icons/flags/mr.svg);
}

.flag-icon-ms {
    background-image: url(/assets/img/icons/flags/ms.svg);
}

.flag-icon-mt {
    background-image: url(/assets/img/icons/flags/mt.svg);
}

.flag-icon-mu {
    background-image: url(/assets/img/icons/flags/mu.svg);
}

.flag-icon-mv {
    background-image: url(/assets/img/icons/flags/mv.svg);
}

.flag-icon-mw {
    background-image: url(/assets/img/icons/flags/mw.svg);
}

.flag-icon-mx {
    background-image: url(/assets/img/icons/flags/mx.svg);
}

.flag-icon-my {
    background-image: url(/assets/img/icons/flags/my.svg);
}

.flag-icon-mz {
    background-image: url(/assets/img/icons/flags/mz.svg);
}

.flag-icon-na {
    background-image: url(/assets/img/icons/flags/na.svg);
}

.flag-icon-nc {
    background-image: url(/assets/img/icons/flags/nc.svg);
}

.flag-icon-ne {
    background-image: url(/assets/img/icons/flags/ne.svg);
}

.flag-icon-nf {
    background-image: url(/assets/img/icons/flags/nf.svg);
}

.flag-icon-ng {
    background-image: url(/assets/img/icons/flags/ng.svg);
}

.flag-icon-ni {
    background-image: url(/assets/img/icons/flags/ni.svg);
}

.flag-icon-nl {
    background-image: url(/assets/img/icons/flags/nl.svg);
}

.flag-icon-no {
    background-image: url(/assets/img/icons/flags/no.svg);
}

.flag-icon-np {
    background-image: url(/assets/img/icons/flags/np.svg);
}

.flag-icon-nr {
    background-image: url(/assets/img/icons/flags/nr.svg);
}

.flag-icon-nu {
    background-image: url(/assets/img/icons/flags/nu.svg);
}

.flag-icon-nz {
    background-image: url(/assets/img/icons/flags/nz.svg);
}

.flag-icon-om {
    background-image: url(/assets/img/icons/flags/om.svg);
}

.flag-icon-pa {
    background-image: url(/assets/img/icons/flags/pa.svg);
}

.flag-icon-pe {
    background-image: url(/assets/img/icons/flags/pe.svg);
}

.flag-icon-pf {
    background-image: url(/assets/img/icons/flags/pf.svg);
}

.flag-icon-pg {
    background-image: url(/assets/img/icons/flags/pg.svg);
}

.flag-icon-ph {
    background-image: url(/assets/img/icons/flags/ph.svg);
}

.flag-icon-pk {
    background-image: url(/assets/img/icons/flags/pk.svg);
}

.flag-icon-pl {
    background-image: url(/assets/img/icons/flags/pl.svg);
}

.flag-icon-pm {
    background-image: url(/assets/img/icons/flags/pm.svg);
}

.flag-icon-pn {
    background-image: url(/assets/img/icons/flags/pn.svg);
}

.flag-icon-pr {
    background-image: url(/assets/img/icons/flags/pr.svg);
}

.flag-icon-ps {
    background-image: url(/assets/img/icons/flags/ps.svg);
}

.flag-icon-pt {
    background-image: url(/assets/img/icons/flags/pt.svg);
}

.flag-icon-pw {
    background-image: url(/assets/img/icons/flags/pw.svg);
}

.flag-icon-py {
    background-image: url(/assets/img/icons/flags/py.svg);
}

.flag-icon-qa {
    background-image: url(/assets/img/icons/flags/qa.svg);
}

.flag-icon-re {
    background-image: url(/assets/img/icons/flags/re.svg);
}

.flag-icon-ro {
    background-image: url(/assets/img/icons/flags/ro.svg);
}

.flag-icon-rs {
    background-image: url(/assets/img/icons/flags/rs.svg);
}

.flag-icon-ru {
    background-image: url(/assets/img/icons/flags/ru.svg);
}

.flag-icon-rw {
    background-image: url(/assets/img/icons/flags/rw.svg);
}

.flag-icon-sa {
    background-image: url(/assets/img/icons/flags/sa.svg);
}

.flag-icon-sb {
    background-image: url(/assets/img/icons/flags/sb.svg);
}

.flag-icon-sc {
    background-image: url(/assets/img/icons/flags/sc.svg);
}

.flag-icon-sd {
    background-image: url(/assets/img/icons/flags/sd.svg);
}

.flag-icon-se {
    background-image: url(/assets/img/icons/flags/se.svg);
}

.flag-icon-sg {
    background-image: url(/assets/img/icons/flags/sg.svg);
}

.flag-icon-sh {
    background-image: url(/assets/img/icons/flags/sh.svg);
}

.flag-icon-si {
    background-image: url(/assets/img/icons/flags/si.svg);
}

.flag-icon-sj {
    background-image: url(/assets/img/icons/flags/sj.svg);
}

.flag-icon-sk {
    background-image: url(/assets/img/icons/flags/sk.svg);
}

.flag-icon-sl {
    background-image: url(/assets/img/icons/flags/sl.svg);
}

.flag-icon-sm {
    background-image: url(/assets/img/icons/flags/sm.svg);
}

.flag-icon-sn {
    background-image: url(/assets/img/icons/flags/sn.svg);
}

.flag-icon-so {
    background-image: url(/assets/img/icons/flags/so.svg);
}

.flag-icon-sr {
    background-image: url(/assets/img/icons/flags/sr.svg);
}

.flag-icon-ss {
    background-image: url(/assets/img/icons/flags/ss.svg);
}

.flag-icon-st {
    background-image: url(/assets/img/icons/flags/st.svg);
}

.flag-icon-sv {
    background-image: url(/assets/img/icons/flags/sv.svg);
}

.flag-icon-sx {
    background-image: url(/assets/img/icons/flags/sx.svg);
}

.flag-icon-sy {
    background-image: url(/assets/img/icons/flags/sy.svg);
}

.flag-icon-sz {
    background-image: url(/assets/img/icons/flags/sz.svg);
}

.flag-icon-tc {
    background-image: url(/assets/img/icons/flags/tc.svg);
}

.flag-icon-td {
    background-image: url(/assets/img/icons/flags/td.svg);
}

.flag-icon-tf {
    background-image: url(/assets/img/icons/flags/tf.svg);
}

.flag-icon-tg {
    background-image: url(/assets/img/icons/flags/tg.svg);
}

.flag-icon-th {
    background-image: url(/assets/img/icons/flags/th.svg);
}

.flag-icon-tj {
    background-image: url(/assets/img/icons/flags/tj.svg);
}

.flag-icon-tk {
    background-image: url(/assets/img/icons/flags/tk.svg);
}

.flag-icon-tl {
    background-image: url(/assets/img/icons/flags/tl.svg);
}

.flag-icon-tm {
    background-image: url(/assets/img/icons/flags/tm.svg);
}

.flag-icon-tn {
    background-image: url(/assets/img/icons/flags/tn.svg);
}

.flag-icon-to {
    background-image: url(/assets/img/icons/flags/to.svg);
}

.flag-icon-tr {
    background-image: url(/assets/img/icons/flags/tr.svg);
}

.flag-icon-tt {
    background-image: url(/assets/img/icons/flags/tt.svg);
}

.flag-icon-tv {
    background-image: url(/assets/img/icons/flags/tv.svg);
}

.flag-icon-tw {
    background-image: url(/assets/img/icons/flags/tw.svg);
}

.flag-icon-tz {
    background-image: url(/assets/img/icons/flags/tz.svg);
}

.flag-icon-ua {
    background-image: url(/assets/img/icons/flags/ua.svg);
}

.flag-icon-ug {
    background-image: url(/assets/img/icons/flags/ug.svg);
}

.flag-icon-um {
    background-image: url(/assets/img/icons/flags/um.svg);
}

.flag-icon-us {
    background-image: url(/assets/img/icons/flags/us.svg);
}

.flag-icon-uy {
    background-image: url(/assets/img/icons/flags/uy.svg);
}

.flag-icon-uz {
    background-image: url(/assets/img/icons/flags/uz.svg);
}

.flag-icon-va {
    background-image: url(/assets/img/icons/flags/va.svg);
}

.flag-icon-vc {
    background-image: url(/assets/img/icons/flags/vc.svg);
}

.flag-icon-ve {
    background-image: url(/assets/img/icons/flags/ve.svg);
}

.flag-icon-vg {
    background-image: url(/assets/img/icons/flags/vg.svg);
}

.flag-icon-vi {
    background-image: url(/assets/img/icons/flags/vi.svg);
}

.flag-icon-vn {
    background-image: url(/assets/img/icons/flags/vn.svg);
}

.flag-icon-vu {
    background-image: url(/assets/img/icons/flags/vu.svg);
}

.flag-icon-wf {
    background-image: url(/assets/img/icons/flags/wf.svg);
}

.flag-icon-ws {
    background-image: url(/assets/img/icons/flags/ws.svg);
}

.flag-icon-ye {
    background-image: url(/assets/img/icons/flags/ye.svg);
}

.flag-icon-yt {
    background-image: url(/assets/img/icons/flags/yt.svg);
}

.flag-icon-za {
    background-image: url(/assets/img/icons/flags/za.svg);
}

.flag-icon-zm {
    background-image: url(/assets/img/icons/flags/zm.svg);
}

.flag-icon-zw {
    background-image: url(/assets/img/icons/flags/zw.svg);
}

.flag-icon-es-ct {
    background-image: url(/assets/img/icons/flags/es-ct.svg);
}

.flag-icon-eu {
    background-image: url(/assets/img/icons/flags/eu.svg);
}

.flag-icon-gb-eng {
    background-image: url(/assets/img/icons/flags/gb-eng.svg);
}

.flag-icon-gb-nir {
    background-image: url(/assets/img/icons/flags/gb-nir.svg);
}

.flag-icon-gb-sct {
    background-image: url(/assets/img/icons/flags/gb-sct.svg);
}

.flag-icon-gb-wls {
    background-image: url(/assets/img/icons/flags/gb-wls.svg);
}

.flag-icon-un {
    background-image: url(/assets/img/icons/flags/un.svg);
}