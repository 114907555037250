:root {
     --fp-primary-bg: #f6f6f6;
     --fp-secondary-bg: #1bc0a0;
     --fp-primary-border-color: #dddddd;

     --fp-main-color: #555555;
     --fp-main-border-radius: 5px;
     --fp-main-control-bg: #ffffff;
     --fp-arrow-color: #121212;


     --fp-primary-color: white;
     --fp-secondary-color: #ddd;
     --fp-tertiary-color: #804f7b;
     --fp-quaternary-color: #1bc0a0;
     --fp-font-color: #ddd;
     --fp-font-color-active: #f8f8f8;
     --fp-main-dark-color: rgba(0, 0, 0, 0.2);
     --fp-secondary-dark-color: #1bc0a0;
     --fp-main-light-color: rgba(255, 255, 255, 0.1);
     --fp-secondary-light-color: rgba(255, 255, 255, 0.7);
     --fp-soft-light-color: rgba(255, 255, 255, 0.015);

     --fp-color-red: #dd3636;
     --fp-color-yellow: #ffca6f;
     --fp-color-green: #62c462;
     --fp-left-width: 0%;
     --fp-color-highlight: #71b7f1;
     --fp-color-warn: #ffca6f;
     --fp-handle-margin: -5px;
     --fp-light-border: rgba(255, 255, 255, 0.05);

     --fp-dark-text-shadow: rgba(0, 0, 0, 0.2);
     --fp-color-input-padding: 22px;
     --fp-input-padding: 5px;
     --fp-padding-elem-classmanager: 5px 6px;
     --fp-upload-padding: 150px 10px;
     --fp-animation-duration: 0.2s;
     --fp-main-font: Helvetica, sans-serif;
     --fp-font-size: 0.75rem;
     --fp-placeholder-background-color: var(--fp-color-green);
     --fp-canvas-top: 40px;
     --fp-flex-item-gap: 5px
}


.CodeMirror {
     font-family: monospace;
     height: 300px;
     color: black;
     direction: ltr
}

.CodeMirror-lines {
     padding: 4px 0
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
     padding: 0 4px
}

.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
     background-color: white
}

.CodeMirror-gutters {
     border-right: 1px solid #ddd;
     background-color: #f7f7f7;
     white-space: nowrap
}

.CodeMirror-linenumber {
     padding: 0 3px 0 5px;
     min-width: 20px;
     text-align: right;
     color: #999;
     white-space: nowrap
}

.CodeMirror-guttermarker {
     color: black
}

.CodeMirror-guttermarker-subtle {
     color: #999
}

.CodeMirror-cursor {
     border-left: 1px solid black;
     border-right: none;
     width: 0
}

.CodeMirror div.CodeMirror-secondarycursor {
     border-left: 1px solid silver
}

.cm-fat-cursor .CodeMirror-cursor {
     width: auto;
     border: 0 !important;
     background: #7e7
}

.cm-fat-cursor div.CodeMirror-cursors {
     z-index: 1
}

.cm-fat-cursor .CodeMirror-line::selection,
.cm-fat-cursor .CodeMirror-line>span::selection,
.cm-fat-cursor .CodeMirror-line>span>span::selection {
     background: transparent
}

.cm-fat-cursor .CodeMirror-line::-moz-selection,
.cm-fat-cursor .CodeMirror-line>span::-moz-selection,
.cm-fat-cursor .CodeMirror-line>span>span::-moz-selection {
     background: transparent
}

.cm-fat-cursor {
     caret-color: transparent
}

@-moz-keyframes blink {
     50% {
          background-color: transparent
     }
}

@-webkit-keyframes blink {
     50% {
          background-color: transparent
     }
}

@keyframes blink {
     50% {
          background-color: transparent
     }
}

.cm-tab {
     display: inline-block;
     text-decoration: inherit
}

.CodeMirror-rulers {
     position: absolute;
     left: 0;
     right: 0;
     top: -50px;
     bottom: 0;
     overflow: hidden
}

.CodeMirror-ruler {
     border-left: 1px solid #ccc;
     top: 0;
     bottom: 0;
     position: absolute
}

.cm-s-default .cm-header {
     color: blue
}

.cm-s-default .cm-quote {
     color: #090
}

.cm-negative {
     color: #d44
}

.cm-positive {
     color: #292
}

.cm-header,
.cm-strong {
     font-weight: bold
}

.cm-em {
     font-style: italic
}

.cm-link {
     text-decoration: underline
}

.cm-strikethrough {
     text-decoration: line-through
}

.cm-s-default .cm-keyword {
     color: #708
}

.cm-s-default .cm-atom {
     color: #219
}

.cm-s-default .cm-number {
     color: #164
}

.cm-s-default .cm-def {
     color: blue
}

.cm-s-default .cm-variable-2 {
     color: #05a
}

.cm-s-default .cm-variable-3,
.cm-s-default .cm-type {
     color: #085
}

.cm-s-default .cm-comment {
     color: #a50
}

.cm-s-default .cm-string {
     color: #a11
}

.cm-s-default .cm-string-2 {
     color: #f50
}

.cm-s-default .cm-meta {
     color: #555
}

.cm-s-default .cm-qualifier {
     color: #555
}

.cm-s-default .cm-builtin {
     color: #30a
}

.cm-s-default .cm-bracket {
     color: #997
}

.cm-s-default .cm-tag {
     color: #170
}

.cm-s-default .cm-attribute {
     color: #00c
}

.cm-s-default .cm-hr {
     color: #999
}

.cm-s-default .cm-link {
     color: #00c
}

.cm-s-default .cm-error {
     color: red
}

.cm-invalidchar {
     color: red
}

.CodeMirror-composing {
     border-bottom: 2px solid
}

div.CodeMirror span.CodeMirror-matchingbracket {
     color: #0b0
}

div.CodeMirror span.CodeMirror-nonmatchingbracket {
     color: #a22
}

.CodeMirror-matchingtag {
     background: rgba(255, 150, 0, 0.3)
}

.CodeMirror-activeline-background {
     background: #e8f2ff
}

.CodeMirror {
     position: relative;
     overflow: hidden;
     background: white
}

.CodeMirror-scroll {
     overflow: scroll !important;
     margin-bottom: -50px;
     margin-right: -50px;
     padding-bottom: 50px;
     height: 100%;
     outline: none;
     position: relative;
     z-index: 0
}

.CodeMirror-sizer {
     position: relative;
     border-right: 50px solid transparent
}

.CodeMirror-vscrollbar,
.CodeMirror-hscrollbar,
.CodeMirror-scrollbar-filler,
.CodeMirror-gutter-filler {
     position: absolute;
     z-index: 6;
     display: none;
     outline: none
}

.CodeMirror-vscrollbar {
     right: 0;
     top: 0;
     overflow-x: hidden;
     overflow-y: scroll
}

.CodeMirror-hscrollbar {
     bottom: 0;
     left: 0;
     overflow-y: hidden;
     overflow-x: scroll
}

.CodeMirror-scrollbar-filler {
     right: 0;
     bottom: 0
}

.CodeMirror-gutter-filler {
     left: 0;
     bottom: 0
}

.CodeMirror-gutters {
     position: absolute;
     left: 0;
     top: 0;
     min-height: 100%;
     z-index: 3
}

.CodeMirror-gutter {
     white-space: normal;
     height: 100%;
     display: inline-block;
     vertical-align: top;
     margin-bottom: -50px
}

.CodeMirror-gutter-wrapper {
     position: absolute;
     z-index: 4;
     background: none !important;
     border: none !important
}

.CodeMirror-gutter-background {
     position: absolute;
     top: 0;
     bottom: 0;
     z-index: 4
}

.CodeMirror-gutter-elt {
     position: absolute;
     cursor: default;
     z-index: 4
}

.CodeMirror-gutter-wrapper ::selection {
     background-color: transparent
}

.CodeMirror-gutter-wrapper ::-moz-selection {
     background-color: transparent
}

.CodeMirror-lines {
     cursor: text;
     min-height: 1px
}

.CodeMirror pre.CodeMirror-line,
.CodeMirror pre.CodeMirror-line-like {
     -moz-border-radius: 0;
     -webkit-border-radius: 0;
     border-radius: 0;
     border-width: 0;
     background: transparent;
     font-family: inherit;
     font-size: inherit;
     margin: 0;
     white-space: pre;
     word-wrap: normal;
     line-height: inherit;
     color: inherit;
     z-index: 2;
     position: relative;
     overflow: visible;
     -webkit-tap-highlight-color: transparent;
     -webkit-font-variant-ligatures: contextual;
     font-variant-ligatures: contextual
}

.CodeMirror-wrap pre.CodeMirror-line,
.CodeMirror-wrap pre.CodeMirror-line-like {
     word-wrap: break-word;
     white-space: pre-wrap;
     word-break: normal
}

.CodeMirror-linebackground {
     position: absolute;
     left: 0;
     right: 0;
     top: 0;
     bottom: 0;
     z-index: 0
}

.CodeMirror-linewidget {
     position: relative;
     z-index: 2;
     padding: .1px
}

.CodeMirror-rtl pre {
     direction: rtl
}

.CodeMirror-code {
     outline: none
}

.CodeMirror-scroll,
.CodeMirror-sizer,
.CodeMirror-gutter,
.CodeMirror-gutters,
.CodeMirror-linenumber {
     -moz-box-sizing: content-box;
     box-sizing: content-box
}

.CodeMirror-measure {
     position: absolute;
     width: 100%;
     height: 0;
     overflow: hidden;
     visibility: hidden
}

.CodeMirror-cursor {
     position: absolute;
     pointer-events: none
}

.CodeMirror-measure pre {
     position: static
}

div.CodeMirror-cursors {
     visibility: hidden;
     position: relative;
     z-index: 3
}

div.CodeMirror-dragcursors {
     visibility: visible
}

.CodeMirror-focused div.CodeMirror-cursors {
     visibility: visible
}

.CodeMirror-selected {
     background: #d9d9d9
}

.CodeMirror-focused .CodeMirror-selected {
     background: #d7d4f0
}

.CodeMirror-crosshair {
     cursor: crosshair
}

.CodeMirror-line::selection,
.CodeMirror-line>span::selection,
.CodeMirror-line>span>span::selection {
     background: #d7d4f0
}

.CodeMirror-line::-moz-selection,
.CodeMirror-line>span::-moz-selection,
.CodeMirror-line>span>span::-moz-selection {
     background: #d7d4f0
}

.cm-searching {
     background-color: #ffa;
     background-color: rgba(255, 255, 0, 0.4)
}

.cm-force-border {
     padding-right: .1px
}

@media print {
     .CodeMirror div.CodeMirror-cursors {
          visibility: hidden
     }
}

.cm-tab-wrap-hack:after {
     content: ""
}

span.CodeMirror-selectedtext {
     background: none
}

.cm-s-hopscotch.CodeMirror {
     background: #322931;
     color: #d5d3d5
}

.cm-s-hopscotch div.CodeMirror-selected {
     background: #433b42 !important
}

.cm-s-hopscotch .CodeMirror-gutters {
     background: #322931;
     border-right: 0px
}

.cm-s-hopscotch .CodeMirror-linenumber {
     color: #797379
}

.cm-s-hopscotch .CodeMirror-cursor {
     border-left: 1px solid #989498 !important
}

.cm-s-hopscotch span.cm-comment {
     color: #b33508
}

.cm-s-hopscotch span.cm-atom {
     color: #c85e7c
}

.cm-s-hopscotch span.cm-number {
     color: #c85e7c
}

.cm-s-hopscotch span.cm-property,
.cm-s-hopscotch span.cm-attribute {
     color: #8fc13e
}

.cm-s-hopscotch span.cm-keyword {
     color: #dd464c
}

.cm-s-hopscotch span.cm-string {
     color: #fdcc59
}

.cm-s-hopscotch span.cm-variable {
     color: #8fc13e
}

.cm-s-hopscotch span.cm-variable-2 {
     color: #1290bf
}

.cm-s-hopscotch span.cm-def {
     color: #fd8b19
}

.cm-s-hopscotch span.cm-error {
     background: #dd464c;
     color: #989498
}

.cm-s-hopscotch span.cm-bracket {
     color: #d5d3d5
}

.cm-s-hopscotch span.cm-tag {
     color: #dd464c
}

.cm-s-hopscotch span.cm-link {
     color: #c85e7c
}

.cm-s-hopscotch .CodeMirror-matchingbracket {
     text-decoration: underline;
     color: white !important
}

.cm-s-hopscotch .CodeMirror-activeline-background {
     background: #302020
}

.sp-container {
     position: absolute;
     top: 0;
     left: 0;
     display: inline-block;
     z-index: 9999994;
     overflow: hidden
}

.sp-container.sp-flat {
     position: relative
}

.sp-container,
.sp-container * {
     -webkit-box-sizing: content-box;
     -moz-box-sizing: content-box;
     box-sizing: content-box
}

.sp-top {
     position: relative;
     width: 100%;
     display: inline-block
}

.sp-top-inner {
     position: absolute;
     top: 0;
     left: 0;
     bottom: 0;
     right: 0
}

.sp-color {
     position: absolute;
     top: 0;
     left: 0;
     bottom: 0;
     right: 20%
}

.sp-hue {
     position: absolute;
     top: 0;
     right: 0;
     bottom: 0;
     left: 84%;
     height: 100%
}

.sp-clear-enabled .sp-hue {
     top: 33px;
     height: 77.5%
}

.sp-fill {
     padding-top: 80%
}

.sp-sat,
.sp-val {
     position: absolute;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0
}

.sp-alpha-enabled .sp-top {
     margin-bottom: 18px
}

.sp-alpha-enabled .sp-alpha {
     display: block
}

.sp-alpha-handle {
     position: absolute;
     top: -4px;
     bottom: -4px;
     width: 6px;
     left: 50%;
     cursor: pointer;
     border: 1px solid #000;
     background: #fff;
     opacity: .8
}

.sp-alpha {
     display: none;
     position: absolute;
     bottom: -14px;
     right: 0;
     left: 0;
     height: 8px
}

.sp-alpha-inner {
     border: solid 1px #333
}

.sp-clear {
     display: none
}

.sp-clear.sp-clear-display {
     background-position: center
}

.sp-clear-enabled .sp-clear {
     display: block;
     position: absolute;
     top: 0px;
     right: 0;
     bottom: 0;
     left: 84%;
     height: 28px
}

.sp-container,
.sp-replacer,
.sp-preview,
.sp-dragger,
.sp-slider,
.sp-alpha,
.sp-clear,
.sp-alpha-handle,
.sp-container.sp-dragging .sp-input,
.sp-container button {
     -webkit-user-select: none;
     -moz-user-select: -moz-none;
     -o-user-select: none;
     user-select: none
}

.sp-container.sp-input-disabled .sp-input-container {
     display: none
}

.sp-container.sp-buttons-disabled .sp-button-container {
     display: none
}

.sp-container.sp-palette-buttons-disabled .sp-palette-button-container {
     display: none
}

.sp-palette-only .sp-picker-container {
     display: none
}

.sp-palette-disabled .sp-palette-container {
     display: none
}

.sp-initial-disabled .sp-initial {
     display: none
}

.sp-sat {
     background-image: -webkit-gradient(linear, 0 0, 100% 0, from(#FFF), to(rgba(204, 154, 129, 0)));
     background-image: -webkit-linear-gradient(left, #FFF, rgba(204, 154, 129, 0));
     background-image: -moz-linear-gradient(left, #fff, rgba(204, 154, 129, 0));
     background-image: -o-linear-gradient(left, #fff, rgba(204, 154, 129, 0));
     background-image: -ms-linear-gradient(left, #fff, rgba(204, 154, 129, 0));
     background-image: linear-gradient(to right, #fff, rgba(204, 154, 129, 0));
     -ms-filter: "progid:DXImageTransform.Microsoft.gradient(GradientType = 1, startColorstr=#FFFFFFFF, endColorstr=#00CC9A81)";
     filter: progid:DXImageTransform.Microsoft.gradient(GradientType=1, startColorstr="#FFFFFFFF", endColorstr="#00CC9A81")
}

.sp-val {
     background-image: -webkit-gradient(linear, 0 100%, 0 0, from(#000000), to(rgba(204, 154, 129, 0)));
     background-image: -webkit-linear-gradient(bottom, #000000, rgba(204, 154, 129, 0));
     background-image: -moz-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
     background-image: -o-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
     background-image: -ms-linear-gradient(bottom, #000, rgba(204, 154, 129, 0));
     background-image: linear-gradient(to top, #000, rgba(204, 154, 129, 0));
     -ms-filter: "progid:DXImageTransform.Microsoft.gradient(startColorstr=#00CC9A81, endColorstr=#FF000000)";
     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00CC9A81", endColorstr="#FF000000")
}

.sp-hue {
     background: -moz-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
     background: -ms-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
     background: -o-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
     background: -webkit-gradient(linear, left top, left bottom, from(#ff0000), color-stop(0.17, #ffff00), color-stop(0.33, #00ff00), color-stop(0.5, #00ffff), color-stop(0.67, #0000ff), color-stop(0.83, #ff00ff), to(#ff0000));
     background: -webkit-linear-gradient(top, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%);
     background: linear-gradient(to bottom, #ff0000 0%, #ffff00 17%, #00ff00 33%, #00ffff 50%, #0000ff 67%, #ff00ff 83%, #ff0000 100%)
}

.sp-1 {
     height: 17%;
     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff0000", endColorstr="#ffff00")
}

.sp-2 {
     height: 16%;
     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ffff00", endColorstr="#00ff00")
}

.sp-3 {
     height: 17%;
     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ff00", endColorstr="#00ffff")
}

.sp-4 {
     height: 17%;
     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#00ffff", endColorstr="#0000ff")
}

.sp-5 {
     height: 16%;
     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#0000ff", endColorstr="#ff00ff")
}

.sp-6 {
     height: 17%;
     filter: progid:DXImageTransform.Microsoft.gradient(startColorstr="#ff00ff", endColorstr="#ff0000")
}

.sp-hidden {
     display: none !important
}

.sp-cf:before,
.sp-cf:after {
     content: "";
     display: table
}

.sp-cf:after {
     clear: both
}

@media(max-device-width: 480px) {
     .sp-color {
          right: 40%
     }

     .sp-hue {
          left: 63%
     }

     .sp-fill {
          padding-top: 60%
     }
}

.sp-dragger {
     border-radius: 5px;
     height: 5px;
     width: 5px;
     border: 1px solid #fff;
     background: #000;
     cursor: pointer;
     position: absolute;
     top: 0;
     left: 0
}

.sp-slider {
     position: absolute;
     top: 0;
     cursor: pointer;
     height: 3px;
     left: -1px;
     right: -1px;
     border: 1px solid #000;
     background: #fff;
     opacity: .8
}

.sp-container {
     border-radius: 0;
     background-color: #ececec;
     border: solid 1px #f0c49b;
     padding: 0
}

.sp-container,
.sp-container button,
.sp-container input,
.sp-color,
.sp-hue,
.sp-clear {
     font: normal 12px "Lucida Grande", "Lucida Sans Unicode", "Lucida Sans", Geneva, Verdana, sans-serif;
     -webkit-box-sizing: border-box;
     -moz-box-sizing: border-box;
     -ms-box-sizing: border-box;
     box-sizing: border-box
}

.sp-top {
     margin-bottom: 3px
}

.sp-color,
.sp-hue,
.sp-clear {
     border: solid 1px #666
}

.sp-input-container {
     float: right;
     width: 100px;
     margin-bottom: 4px
}

.sp-initial-disabled .sp-input-container {
     width: 100%
}

.sp-input {
     font-size: 12px !important;
     border: 1px inset;
     padding: 4px 5px;
     margin: 0;
     width: 100%;
     background: rgba(0, 0, 0, 0);
     border-radius: 3px;
     color: #222
}

.sp-input:focus {
     border: 1px solid orange
}

.sp-input.sp-validation-error {
     border: 1px solid red;
     background: #fdd
}

.sp-picker-container,
.sp-palette-container {
     float: left;
     position: relative;
     padding: 10px;
     padding-bottom: 300px;
     margin-bottom: -290px
}

.sp-picker-container {
     width: 172px;
     border-left: solid 1px #fff
}

.sp-palette-container {
     border-right: solid 1px #ccc
}

.sp-palette-only .sp-palette-container {
     border: 0
}

.sp-palette .sp-thumb-el {
     display: block;
     position: relative;
     float: left;
     width: 24px;
     height: 15px;
     margin: 3px;
     cursor: pointer;
     border: solid 2px rgba(0, 0, 0, 0)
}

.sp-palette .sp-thumb-el:hover,
.sp-palette .sp-thumb-el.sp-thumb-active {
     border-color: orange
}

.sp-thumb-el {
     position: relative
}

.sp-initial {
     float: left;
     border: solid 1px #333
}

.sp-initial span {
     width: 30px;
     height: 25px;
     border: none;
     display: block;
     float: left;
     margin: 0
}

.sp-initial .sp-clear-display {
     background-position: center
}

.sp-palette-button-container,
.sp-button-container {
     float: right
}

.sp-replacer {
     margin: 0;
     overflow: hidden;
     cursor: pointer;
     padding: 4px;
     display: inline-block;
     border: solid 1px #91765d;
     background: #eee;
     color: #333;
     vertical-align: middle
}

.sp-replacer:hover,
.sp-replacer.sp-active {
     border-color: #f0c49b;
     color: #111
}

.sp-replacer.sp-disabled {
     cursor: default;
     border-color: silver;
     color: silver
}

.sp-dd {
     padding: 2px 0;
     height: 16px;
     line-height: 16px;
     float: left;
     font-size: 10px
}

.sp-preview {
     position: relative;
     width: 25px;
     height: 20px;
     border: solid 1px #222;
     margin-right: 5px;
     float: left;
     z-index: 0
}

.sp-palette {
     max-width: 220px
}

.sp-palette .sp-thumb-el {
     width: 16px;
     height: 16px;
     margin: 2px 1px;
     border: solid 1px #d0d0d0
}

.sp-container {
     padding-bottom: 0
}

.sp-container button {
     background-color: #eee;
     background-image: -webkit-linear-gradient(top, #eeeeee, #cccccc);
     background-image: -moz-linear-gradient(top, #eeeeee, #cccccc);
     background-image: -ms-linear-gradient(top, #eeeeee, #cccccc);
     background-image: -o-linear-gradient(top, #eeeeee, #cccccc);
     background-image: linear-gradient(to bottom, #eeeeee, #cccccc);
     border: 1px solid #ccc;
     border-bottom: 1px solid #bbb;
     border-radius: 3px;
     color: #333;
     font-size: 14px;
     line-height: 1;
     padding: 5px 4px;
     text-align: center;
     text-shadow: 0 1px 0 #eee;
     vertical-align: middle
}

.sp-container button:hover {
     background-color: #ddd;
     background-image: -webkit-linear-gradient(top, #dddddd, #bbbbbb);
     background-image: -moz-linear-gradient(top, #dddddd, #bbbbbb);
     background-image: -ms-linear-gradient(top, #dddddd, #bbbbbb);
     background-image: -o-linear-gradient(top, #dddddd, #bbbbbb);
     background-image: linear-gradient(to bottom, #dddddd, #bbbbbb);
     border: 1px solid #bbb;
     border-bottom: 1px solid #999;
     cursor: pointer;
     text-shadow: 0 1px 0 #ddd
}

.sp-container button:active {
     border: 1px solid #aaa;
     border-bottom: 1px solid #888;
     -webkit-box-shadow: inset 0 0 5px 2px #aaa, 0 1px 0 0 #eee;
     -moz-box-shadow: inset 0 0 5px 2px #aaa, 0 1px 0 0 #eee;
     -ms-box-shadow: inset 0 0 5px 2px #aaa, 0 1px 0 0 #eee;
     -o-box-shadow: inset 0 0 5px 2px #aaa, 0 1px 0 0 #eee;
     box-shadow: inset 0 0 5px 2px #aaa, 0 1px 0 0 #eee
}

.sp-cancel {
     font-size: 11px;
     color: #d93f3f !important;
     margin: 0;
     padding: 2px;
     margin-right: 5px;
     vertical-align: middle;
     text-decoration: none
}

.sp-cancel:hover {
     color: #d93f3f !important;
     text-decoration: underline
}

.sp-palette span:hover,
.sp-palette span.sp-thumb-active {
     border-color: #000
}

.sp-preview,
.sp-alpha,
.sp-thumb-el {
     position: relative;
     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==)
}

.sp-preview-inner,
.sp-alpha-inner,
.sp-thumb-inner {
     display: block;
     position: absolute;
     top: 0;
     left: 0;
     bottom: 0;
     right: 0
}

.sp-palette .sp-thumb-inner {
     background-position: 50% 50%;
     background-repeat: no-repeat
}

.sp-palette .sp-thumb-light.sp-thumb-active .sp-thumb-inner {
     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAIVJREFUeNpiYBhsgJFMffxAXABlN5JruT4Q3wfi/0DsT64h8UD8HmpIPCWG/KemIfOJCUB+Aoacx6EGBZyHBqI+WsDCwuQ9mhxeg2A210Ntfo8klk9sOMijaURm7yc1UP2RNCMbKE9ODK1HM6iegYLkfx8pligC9lCD7KmRof0ZhjQACDAAceovrtpVBRkAAAAASUVORK5CYII=)
}

.sp-palette .sp-thumb-dark.sp-thumb-active .sp-thumb-inner {
     background-image: url(data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAAadEVYdFNvZnR3YXJlAFBhaW50Lk5FVCB2My41LjEwMPRyoQAAAMdJREFUOE+tkgsNwzAMRMugEAahEAahEAZhEAqlEAZhEAohEAYh81X2dIm8fKpEspLGvudPOsUYpxE2BIJCroJmEW9qJ+MKaBFhEMNabSy9oIcIPwrB+afvAUFoK4H0tMaQ3XtlrggDhOVVMuT4E5MMG0FBbCEYzjYT7OxLEvIHQLY2zWwQ3D+9luyOQTfKDiFD3iUIfPk8VqrKjgAiSfGFPecrg6HN6m/iBcwiDAo7WiBeawa+Kwh7tZoSCGLMqwlSAzVDhoK+6vH4G0P5wdkAAAAASUVORK5CYII=)
}

.sp-clear-display {
     background-repeat: no-repeat;
     background-position: center;
     background-image: url(data:image/gif;base64,R0lGODlhFAAUAPcAAAAAAJmZmZ2dnZ6enqKioqOjo6SkpKWlpaampqenp6ioqKmpqaqqqqurq/Hx8fLy8vT09PX19ff39/j4+Pn5+fr6+vv7+wAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAACH5BAEAAP8ALAAAAAAUABQAAAihAP9FoPCvoMGDBy08+EdhQAIJCCMybCDAAYUEARBAlFiQQoMABQhKUJBxY0SPICEYHBnggEmDKAuoPMjS5cGYMxHW3IiT478JJA8M/CjTZ0GgLRekNGpwAsYABHIypcAgQMsITDtWJYBR6NSqMico9cqR6tKfY7GeBCuVwlipDNmefAtTrkSzB1RaIAoXodsABiZAEFB06gIBWC1mLVgBa0AAOw==)
}

.fp-is__grab,
.fp-is__grab * {
     cursor: grab !important
}

[data-gjs-type="wrapper"] {
     border-radius: 0 0 var(--fp_main_border_radius, 8px) 0;
}

.fp-is__grabbing,
.fp-is__grabbing * {
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     -ms-user-select: none;
     -o-user-select: none;
     user-select: none;
     cursor: grabbing !important
}

.fp-one-bg {
     background-color: var(--fp-primary-color)
}

.fp-one-color {
     color: var(--fp-primary-color)
}

.fp-one-color-h:hover {
     color: var(--fp-primary-color)
}

.fp-two-bg {
     background-color: var(--fp-secondary-color)
}

.fp-two-color {
     color: var(--fp-secondary-color)
}

.fp-two-color-h:hover {
     color: var(--fp-secondary-color)
}

.fp-three-bg {
     background-color: var(--fp-tertiary-color)
}

.fp-three-color {
     color: var(--fp-tertiary-color)
}

.fp-three-color-h:hover {
     color: var(--fp-tertiary-color)
}

.fp-four-bg {
     background-color: var(--fp-quaternary-color)
}

.fp-four-color {
     color: var(--fp-quaternary-color)
}

.fp-four-color-h:hover {
     color: var(--fp-quaternary-color)
}

.fp-danger-bg {
     background-color: var(--fp-color-red)
}

.fp-danger-color {
     color: var(--fp-color-red)
}

.fp-danger-color-h:hover {
     color: var(--fp-color-red)
}

.fp-bg-main,
.fp-sm-colorp-c,
.fp-off-prv {
     background-color: var(--fp-main-color)
}

.fp-color-main,
.fp-sm-stack #fp-sm-add,
.fp-off-prv {
     color: var(--fp-font-color);
     fill: var(--fp-font-color)
}

.fp-color-active {
     color: var(--fp-font-color-active);
     fill: var(--fp-font-color-active)
}

.fp-color-warn {
     color: var(--fp-color-warn);
     fill: var(--fp-color-warn)
}

.fp-color-hl {
     color: var(--fp-color-highlight);
     fill: var(--fp-color-highlight)
}

.fp-invis-invis,
.fp-clm-tags #fp-clm-new,
.fp-no-app {
     background-color: rgba(0, 0, 0, 0);
     border: none;
     color: inherit
}

.fp-no-app {
     height: 10px
}

.fp-test::btn {
     color: "#fff"
}

.opac50 {
     opacity: .5;
     filter: alpha(opacity=50)
}

.fp-checker-bg,
.fp-field-colorp-c,
.checker-bg,
.fp-sm-layer-preview {
     background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAwAAAAMCAIAAADZF8uwAAAAGUlEQVQYV2M4gwH+YwCGIasIUwhT25BVBADtzYNYrHvv4gAAAABJRU5ErkJggg==")
}

.fp-no-user-select,
.fp-rte-toolbar,
.fp-layer-name,
.fp-grabbing,
.fp-grabbing * {
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     -ms-user-select: none;
     -o-user-select: none;
     user-select: none
}

.fp-no-pointer-events,
.fp-margin-v-el,
.fp-padding-v-el,
.fp-fixedmargin-v-el,
.fp-fixedpadding-v-el,
.fp-resizer-c {
     pointer-events: none
}

.fp-no-pointer {
     cursor: not-allowed !important;
}

.fp-bdrag {
     pointer-events: none !important;
     position: absolute !important;
     z-index: 10 !important;
     width: auto
}

.fp-drag-helper {
     background-color: var(--fp-secondary-bg) !important;
     border-radius: var(--fp-main-border-radius);
     pointer-events: none !important;
     position: absolute !important;
     z-index: 10 !important;
     transform: scale(0.3) !important;
     transform-origin: top left !important;
     -webkit-transform-origin: top left !important;
     margin: 15px !important;
     transition: none !important;
     outline: none !important
}

.fp-grabbing,
.fp-grabbing * {
     cursor: grabbing !important;
     cursor: -webkit-grabbing !important
}

.fp-grabbing {
     overflow: hidden
}

.fp-off-prv {
     position: relative;
     z-index: 10;
     padding: 5px;
     cursor: pointer
}

.fp-editor-cont ::-webkit-scrollbar-track {
     background: var(--fp-secondary-dark-color)
}

.fp-editor-cont ::-webkit-scrollbar-thumb {
     background-color: rgba(255, 255, 255, .2)
}

.fp-editor-cont ::-webkit-scrollbar {
     width: 8px
}

.no-select,
.fp-clm-tags #fp-clm-close,
.fp-category-title,
.fp-layer-title,
.fp-block-category .fp-title,
.fp-sm-sector-title,
.fp-com-no-select,
.fp-com-no-select img {
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     -ms-user-select: none;
     -o-user-select: none;
     user-select: none
}

.fp-no-touch-actions {
     touch-action: none
}

.fp-disabled {
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     -ms-user-select: none;
     -o-user-select: none;
     user-select: none;
     opacity: .5;
     filter: alpha(opacity=50)
}

.fp-editor {
     font-family: var(--fp-main-font);
     font-size: var(--fp-font-size);
     position: relative;
     box-sizing: border-box;
     height: 100%
}

.fp-freezed,
.fp-freezed {
     opacity: .5;
     filter: alpha(opacity=50);
     pointer-events: none
}

.fp-traits-label {
     border-bottom: 1px solid var(--fp-main-dark-color);
     font-weight: lighter;
     margin-bottom: 5px;
     padding: 10px;
     text-align: left
}

.fp-label-wrp {
     width: 30%;
     min-width: 30%
}

.fp-field-wrp {
     flex-grow: 1
}

.fp-trt-header {
     font-weight: lighter;
     padding: 10px
}

.fp-trt-trait {
     display: flex;
     justify-content: flex-start;
     padding: 5px 10px;
     font-weight: lighter;
     align-items: center;
     text-align: left
}

.fp-trt-traits {
     font-size: var(--fp-font-size);
     padding-left: 10px;
}

.fp-trt-trait .fp-label {
     text-align: left;
     text-overflow: ellipsis;
     overflow: hidden
}

.fp-guide-info {
     position: absolute
}

.fp-guide-info__content {
     position: absolute;
     height: 100%;
     display: flex;
     width: 100%;
     padding: 5px
}

.fp-guide-info__line {
     position: relative;
     margin: auto
}

.fp-guide-info__line::before,
.fp-guide-info__line::after {
     content: "";
     display: block;
     position: absolute;
     background-color: inherit
}

.fp-guide-info__y {
     padding: 0 5px
}

.fp-guide-info__y .fp-guide-info__content {
     justify-content: center
}

.fp-guide-info__y .fp-guide-info__line {
     width: 100%;
     height: 1px
}

.fp-guide-info__y .fp-guide-info__line::before,
.fp-guide-info__y .fp-guide-info__line::after {
     width: 1px;
     height: 10px;
     top: 0;
     bottom: 0;
     left: 0;
     margin: auto
}

.fp-guide-info__y .fp-guide-info__line::after {
     left: auto;
     right: 0
}

.fp-guide-info__x {
     padding: 5px 0
}

.fp-guide-info__x .fp-guide-info__content {
     align-items: center
}

.fp-guide-info__x .fp-guide-info__line {
     height: 100%;
     width: 1px
}

.fp-guide-info__x .fp-guide-info__line::before,
.fp-guide-info__x .fp-guide-info__line::after {
     width: 10px;
     height: 1px;
     left: 0;
     right: 0;
     top: 0;
     margin: auto;
     transform: translateX(-50%)
}

.fp-guide-info__x .fp-guide-info__line::after {
     top: auto;
     bottom: 0
}

.fp-badge {
     white-space: nowrap
}

.fp-badge__icon {
     vertical-align: middle;
     display: inline-block;
     width: 15px;
     height: 15px
}

.fp-badge__icon svg {
     fill: currentColor
}

.fp-badge__name {
     display: inline-block;
     vertical-align: middle
}

.fp-frame-wrapper {
     position: absolute;
     width: 100%;
     height: 100%;
     left: 0;
     right: 0;
     margin: auto
}

.fp-frame-wrapper--anim {
     transition: width .35s ease, height .35s ease
}

.fp-frame-wrapper__top {
     transform: translateY(-100%) translateX(-50%);
     display: flex;
     padding: 5px 0;
     position: absolute;
     width: 100%;
     left: 50%;
     top: 0
}

.fp-frame-wrapper__top-r {
     margin-left: auto
}

.fp-frame-wrapper__left {
     position: absolute;
     left: 0;
     transform: translateX(-100%) translateY(-50%);
     height: 100%;
     top: 50%
}

.fp-frame-wrapper__bottom {
     position: absolute;
     bottom: 0;
     transform: translateY(100%) translateX(-50%);
     width: 100%;
     left: 50%
}

.fp-frame-wrapper__right {
     position: absolute;
     right: 0;
     transform: translateX(100%) translateY(-50%);
     height: 100%;
     top: 50%
}

.fp-frame-wrapper__icon {
     width: 24px;
     cursor: pointer
}

.fp-frame-wrapper__icon>svg {
     fill: currentColor
}

.fp-padding-v-top,
.fp-fixedpadding-v-top {
     width: 100%;
     top: 0;
     left: 0
}

.fp-padding-v-right,
.fp-fixedpadding-v-right {
     right: 0
}

.fp-padding-v-bottom,
.fp-fixedpadding-v-bottom {
     width: 100%;
     left: 0;
     bottom: 0
}

.fp-padding-v-left,
.fp-fixedpadding-v-left {
     left: 0
}

.fp-cv-canvas {
     box-sizing: border-box;
     width: calc(100% - var(--fp-left-width));
     height: calc(100% - var(--fp-canvas-top));
     bottom: 0;
     overflow: hidden;
     z-index: 1;
     position: absolute;
     left: 0;
     top: var(--fp-canvas-top)
}

.fp-cv-canvas-bg {
     background-color: rgba(0, 0, 0, .15)
}

.fp-cv-canvas.fp-cui {
     width: 100%;
     height: 100%;
     top: 0
}

.fp-cv-canvas.fp-is__grab .fp-cv-canvas__frames,
.fp-cv-canvas.fp-is__grabbing .fp-cv-canvas__frames {
     pointer-events: none
}

.fp-cv-canvas__frames {
     position: absolute;
     top: 0;
     left: 0;
     width: 100%;
     height: 100%
}

.fp-cv-canvas__spots {
     position: absolute;
     pointer-events: none;
     z-index: 1
}

.fp-cv-canvas .fp-ghost {
     display: none;
     pointer-events: none;
     background-color: #5b5b5b;
     border: 2px dashed #ccc;
     position: absolute;
     z-index: 10;
     opacity: .55;
     filter: alpha(opacity=55)
}

.fp-cv-canvas .fp-highlighter,
.fp-cv-canvas .fp-highlighter-sel {
     position: absolute;
     outline: 1px solid var(--fp-secondary-bg);
     outline-offset: -1px;
     pointer-events: none;
     width: 100%;
     height: 100%;
     border-radius: 0 var(--fp-main-border-radius) var(--fp-main-border-radius);
}

.fp-cv-canvas .fp-highlighter-warning {
     outline: 3px solid var(--fp-color-yellow)
}

.fp-cv-canvas .fp-highlighter-sel {
     outline: 2px solid var(--fp-secondary-bg);
     outline-offset: -2px
}

.fp-cv-canvas #fp-tools,
.fp-cv-canvas .fp-tools {
     width: 100%;
     height: 100%;
     position: absolute;
     top: 0;
     left: 0;
     outline: none;
     z-index: 1
}

.fp-cv-canvas * {
     box-sizing: border-box
}

.fp-frame {
     outline: medium none;
     height: 100%;
     width: 100%;
     border: none;
     margin: auto;
     display: block;
     transition: width .35s ease, height .35s ease;
     position: absolute;
     top: 0;
     bottom: 0;
     left: 0;
     right: 0
}

.fp-toolbar {
     position: absolute;
     background-color: var(--fp-secondary-bg);
     border-radius: var(--fp-main-border-radius);
     white-space: nowrap;
     color: #fff;
     z-index: 10;
     top: 0;
     left: 0
}

.fp-toolbar-item {
     width: 26px;
     padding: 5px;
     cursor: pointer;
     display: inline-block
}

.fp-toolbar-item svg {
     fill: currentColor;
     vertical-align: middle
}

.fp-resizer-c {
     position: absolute;
     left: 0;
     top: 0;
     width: 100%;
     height: 100%;
     z-index: 9
}

.fp-margin-v-el,
.fp-padding-v-el,
.fp-fixedmargin-v-el,
.fp-fixedpadding-v-el {
     opacity: .1;
     filter: alpha(opacity=10);
     position: absolute;
     background-color: #ff0
}

.fp-fixedmargin-v-el,
.fp-fixedpadding-v-el {
     opacity: .2;
     filter: alpha(opacity=20)
}

.fp-padding-v-el,
.fp-fixedpadding-v-el {
     background-color: navy
}

.fp-resizer-h {
     pointer-events: all;
     position: absolute;
     border: 3px solid var(--fp-secondary-bg);
     width: 10px;
     height: 10px;
     background-color: #fff;
     margin: var(--fp-handle-margin)
}

.fp-resizer-h-tl {
     top: 0;
     left: 0;
     cursor: nwse-resize
}

.fp-resizer-h-tr {
     top: 0;
     right: 0;
     cursor: nesw-resize
}

.fp-resizer-h-tc {
     top: 0;
     margin: var(--fp-handle-margin) auto;
     left: 0;
     right: 0;
     cursor: ns-resize
}

.fp-resizer-h-cl {
     left: 0;
     margin: auto var(--fp-handle-margin);
     top: 0;
     bottom: 0;
     cursor: ew-resize
}

.fp-resizer-h-cr {
     margin: auto var(--fp-handle-margin);
     top: 0;
     bottom: 0;
     right: 0;
     cursor: ew-resize
}

.fp-resizer-h-bl {
     bottom: 0;
     left: 0;
     cursor: nesw-resize
}

.fp-resizer-h-bc {
     bottom: 0;
     margin: var(--fp-handle-margin) auto;
     left: 0;
     right: 0;
     cursor: ns-resize
}

.fp-resizer-h-br {
     bottom: 0;
     right: 0;
     cursor: nwse-resize
}

.fp-pn-panel .fp-resizer-h {
     background-color: rgba(0, 0, 0, .2);
     border: none;
     opacity: 0;
     transition: opacity .25s
}

.fp-pn-panel .fp-resizer-h:hover {
     opacity: 1
}

.fp-pn-panel .fp-resizer-h-tc,
.fp-pn-panel .fp-resizer-h-bc {
     margin: 0 auto;
     width: 100%
}

.fp-pn-panel .fp-resizer-h-cr,
.fp-pn-panel .fp-resizer-h-cl {
     margin: auto 0;
     height: 100%
}

.fp-resizing .fp-highlighter,
.fp-resizing .fp-badge {
     display: none !important
}

.fp-resizing-tl * {
     cursor: nwse-resize !important
}

.fp-resizing-tr * {
     cursor: nesw-resize !important
}

.fp-resizing-tc * {
     cursor: ns-resize !important
}

.fp-resizing-cl * {
     cursor: ew-resize !important
}

.fp-resizing-cr * {
     cursor: ew-resize !important
}

.fp-resizing-bl * {
     cursor: nesw-resize !important
}

.fp-resizing-bc * {
     cursor: ns-resize !important
}

.fp-resizing-br * {
     cursor: nwse-resize !important
}

.btn-cl,
.fp-am-close,
.fp-mdl-btn-close {
     opacity: .3;
     filter: alpha(opacity=30);
     font-size: 25px;
     cursor: pointer
}

.btn-cl:hover,
.fp-am-close:hover,
.fp-mdl-btn-close:hover {
     opacity: .7;
     filter: alpha(opacity=70)
}

.no-dots,
.ui-resizable-handle {
     border: none !important;
     margin: 0 !important;
     outline: none !important
}

.fp-com-dashed * {
     outline: 1px dashed #888;
     outline-offset: -2px;
     box-sizing: border-box
}

.fp-com-badge,
.fp-badge {
     pointer-events: none;
     background-color: var(--fp-secondary-bg);
     color: #fff;
     padding: 2px;
     position: absolute;
     z-index: 1;
     font-size: 12px;
     outline: none;
     display: none;
     border-radius: var(--fp-main-border-radius) var(--fp-main-border-radius) 0 0;
     min-width: 50px;
     text-align: center;
}

.fp-badge-warning {
     background-color: var(--fp-color-yellow)
}

.fp-placeholder,
.fp-com-placeholder,
.fp-placeholder {
     position: absolute;
     z-index: 10;
     pointer-events: none;
     display: none
}

.fp-placeholder,
.fp-placeholder {
     border-style: solid !important;
     outline: none;
     box-sizing: border-box;
     transition: top var(--fp-animation-duration), left var(--fp-animation-duration), width var(--fp-animation-duration), height var(--fp-animation-duration)
}

.fp-placeholder.horizontal,
.fp-com-placeholder.horizontal,
.fp-placeholder.horizontal {
     border-color: rgba(0, 0, 0, 0) var(--fp-placeholder-background-color);
     border-width: 3px 5px;
     margin: -3px 0 0
}

.fp-placeholder.vertical,
.fp-com-placeholder.vertical,
.fp-placeholder.vertical {
     border-color: var(--fp-placeholder-background-color) rgba(0, 0, 0, 0);
     border-width: 5px 3px;
     margin: 0 0 0 -3px
}

.fp-placeholder-int,
.fp-com-placeholder-int,
.fp-placeholder-int {
     background-color: var(--fp-placeholder-background-color);
     box-shadow: 0 0 3px rgba(0, 0, 0, .2);
     height: 100%;
     width: 100%;
     pointer-events: none;
     padding: 1.5px;
     outline: none
}

.fp-pn-panel {
     display: inline-block;
     position: absolute;
     box-sizing: border-box;
     text-align: center;
     padding: 5px;
     z-index: 3
}

.fp-pn-panel .icon-undo,
.fp-pn-panel .icon-redo {
     font-size: 20px;
     height: 30px;
     width: 25px
}

.fp-pn-commands {
     width: calc(100% - var(--fp-left-width));
     left: 0;
     top: 5px;
     box-shadow: 0px 2px 2px var(--fp-main-dark-color);
}

.fp-pn-options {
     right: var(--fp-left-width);
     top: 0
}

.fp-pn-views {
     border-bottom: 2px solid var(--fp-main-dark-color);
     right: 0;
     width: var(--fp-left-width);
     z-index: 4
}

.fp-pn-views-container {
     height: 100%;
     padding: 42px 0 0;
     right: 0;
     width: var(--fp-left-width);
     overflow: auto;
     box-shadow: 0 0 5px var(--fp-main-dark-color)
}

.fp-pn-buttons {
     align-items: center;
     display: flex;
     justify-content: space-between
}

.fp-pn-devices-c .fp-pn-buttons {
     padding-top: 3px;
}

.fp-pn-btn {
     box-sizing: border-box;
     min-height: 30px;
     min-width: 30px;
     line-height: 21px;
     background-color: rgba(0, 0, 0, 0);
     border: none;
     font-size: 18px;
     margin-right: 5px;
     border-radius: 2px;
     padding: 4px;
     position: relative;
     cursor: pointer
}

.fp-pn-btn.fp-pn-active {
     background-color: rgba(0, 0, 0, .15);
     box-shadow: 0 0 3px rgba(0, 0, 0, .25) inset
}

.fp-pn-devices-c .fp-pn-btn:nth-child(2) {
     display: none;
}

.fp-pn-btn svg {
     fill: currentColor
}

.fp-pn-btn svg path {
     fill: var(--fp-main-color, #121212);
}

.fp-pn-btn.fp-pn-active svg path {
     fill: var(--fp-quaternary-color, #1bc0a0);
}

.fp-label {
     line-height: 18px
}

.fp-fields {
     display: flex
}

.fp-select {
     padding: 0;
     width: 100%
}

.fp-select select {
     padding-right: 10px
}

.fp-select:-moz-focusring,
.fp-select select:-moz-focusring {
     color: rgba(0, 0, 0, 0);
     text-shadow: 0 0 0 var(--fp-secondary-light-color)
}

.fp-input:focus,
.fp-button:focus,
.fp-btn-prim:focus,
.fp-select:focus,
.fp-select select:focus {
     outline: none
}

.fp-sm-sectors .fp-sm-sector,
.fp-blocks-cs .fp-block-category {
     margin-bottom: 2px;
}

div[data-fp-block-category="structures"] .fp-blocks-c {
     display: block;
}

div[data-fp-block-category="structures"] .fp-blocks-c .fp-block {
     width: 100%;
     min-height: unset;
}

div[data-fp-block-category="structures"] .fp-blocks-c .fp-block .fp-structure {
     background: #e8f7f4;
     height: 30px;
     border: 1px dashed #1bc0a0;
     color: #1bc0a0;
     padding: 0 10px px;
     display: block;
     border-radius: 2px;
     width: 100%;
}

.fp-field input,
.fp-field select,
.fp-field textarea {
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none;
     color: inherit;
     border: none;
     background-color: rgba(0, 0, 0, 0);
     box-sizing: border-box;
     width: 100%;
     position: relative;
     padding: var(--fp-input-padding);
     z-index: 1
}

.fp-field input:focus,
.fp-field select:focus,
.fp-field textarea:focus {
     outline: none
}

.fp-field input[type=number] {
     -moz-appearance: textfield
}

.fp-field input[type=number]::-webkit-outer-spin-button,
.fp-field input[type=number]::-webkit-inner-spin-button {
     -webkit-appearance: none;
     margin: 0
}

.fp-field-range {
     flex: 9 1 auto
}

.fp-field-integer input {
     padding-right: 30px
}

.fp-select option,
.fp-field-select option,
.fp-clm-select option,
.fp-sm-select option,
.fp-fields option,
.fp-sm-unit option {
     background-color: var(--fp-main-color);
     color: var(--fp-font-color)
}

.fp-field {
     background-color: var(--fp-main-control-bg);
     border: none;
     box-shadow: none;
     border: 1px solid var(--fp-primary-border-color);
     border-radius: var(--fp-main-border-radius);
     box-sizing: border-box;
     padding: 0;
     position: relative
}

.fp-field textarea {
     resize: vertical
}

.fp-field .fp-sel-arrow {
     height: 100%;
     width: 9px;
     position: absolute;
     right: 0;
     top: 0;
     z-index: 0
}

.fp-field .fp-d-s-arrow {
     bottom: 0;
     top: 0;
     margin: auto;
     right: var(--fp-input-padding);
     border-top: 4px solid var(--fp-arrow-color);
     position: absolute;
     height: 0;
     width: 0;
     border-left: 3px solid rgba(0, 0, 0, 0);
     border-right: 4px solid rgba(0, 0, 0, 0);
     cursor: pointer
}

.fp-field-arrows {
     position: absolute;
     cursor: ns-resize;
     margin: auto;
     height: 20px;
     width: 9px;
     z-index: 10;
     bottom: 0;
     right: calc(var(--fp-input-padding) - 2px);
     top: 0
}

.fp-field-color,
.fp-field-radio {
     width: 100%
}

.fp-field-color input {
     padding-right: var(--fp-color-input-padding);
     box-sizing: border-box
}

.fp-field-colorp {
     border-left: 1px solid var(--fp-main-dark-color);
     box-sizing: border-box;
     height: 100%;
     padding: 2px;
     position: absolute;
     right: 0;
     top: 0;
     width: var(--fp-color-input-padding);
     z-index: 10
}

.fp-field-colorp .fp-checker-bg,
.fp-field-colorp .fp-field-colorp-c {
     height: 100%;
     width: 100%;
     border-radius: 1px
}

.fp-field-colorp-c {
     height: 100%;
     position: relative;
     width: 100%
}

.fp-field-color-picker {
     background-color: var(--fp-font-color);
     cursor: pointer;
     height: 100%;
     width: 100%;
     box-shadow: 0 0 1px var(--fp-main-dark-color);
     border-radius: 1px;
     position: absolute;
     top: 0
}

.fp-field-checkbox {
     padding: 0;
     width: 17px;
     height: 17px;
     display: block;
     cursor: pointer
}

.fp-field-checkbox input {
     display: none
}

.fp-field-checkbox input:checked+.fp-chk-icon {
     border-color: rgba(255, 255, 255, .5);
     border-width: 0 2px 2px 0;
     border-style: solid
}

.fp-radio-item {
     flex: 1 1 auto;
     text-align: center;
     border-left: 1px solid var(--fp-dark-text-shadow)
}

.fp-radio-item:first-child {
     border: none
}

.fp-radio-item:hover {
     background: var(--fp-main-dark-color)
}

.fp-radio-item input {
     display: none
}

.fp-radio-item input:checked+.fp-radio-item-label {
     background-color: rgba(255, 255, 255, .2)
}

.fp-radio-items {
     display: flex
}

.fp-radio-item-label {
     cursor: pointer;
     display: block;
     padding: var(--fp-input-padding)
}

.fp-field-units {
     position: absolute;
     margin: auto;
     right: 10px;
     bottom: 0;
     top: 0
}

.fp-field-unit {
     position: absolute;
     right: 10px;
     top: 3px;
     font-size: 10px;
     color: var(--fp-arrow-color);
     cursor: pointer
}

.fp-input-unit {
     text-align: center
}

.fp-field-arrow-u,
.fp-field-arrow-d {
     position: absolute;
     height: 0;
     width: 0;
     border-left: 3px solid rgba(0, 0, 0, 0);
     border-right: 4px solid rgba(0, 0, 0, 0);
     border-top: 4px solid var(--fp-arrow-color);
     bottom: 4px;
     cursor: pointer
}

.fp-field-arrow-u {
     border-bottom: 4px solid var(--fp-arrow-color);
     border-top: none;
     top: 4px
}

.fp-field-select {
     padding: 0
}

.fp-field-range {
     background-color: rgba(0, 0, 0, 0);
     border: none;
     box-shadow: none;
     padding: 0
}

.fp-field-range input {
     margin: 0;
     height: 100%
}

.fp-field-range input:focus {
     outline: none
}

.fp-field-range input::-webkit-slider-thumb {
     -webkit-appearance: none;
     margin-top: -4px;
     height: 10px;
     width: 10px;
     border: 1px solid var(--fp-main-dark-color);
     border-radius: 100%;
     background-color: var(--fp-font-color);
     cursor: pointer
}

.fp-field-range input::-moz-range-thumb {
     height: 10px;
     width: 10px;
     border: 1px solid var(--fp-main-dark-color);
     border-radius: 100%;
     background-color: var(--fp-font-color);
     cursor: pointer
}

.fp-field-range input::-ms-thumb {
     height: 10px;
     width: 10px;
     border: 1px solid var(--fp-main-dark-color);
     border-radius: 100%;
     background-color: var(--fp-font-color);
     cursor: pointer
}

.fp-field-range input::-moz-range-track {
     background-color: var(--fp-main-control-bg);
     border-radius: 1px;
     margin-top: 3px;
     height: 3px
}

.fp-field-range input::-webkit-slider-runnable-track {
     background-color: var(--fp-main-control-bg);
     border-radius: 1px;
     margin-top: 3px;
     height: 3px
}

.fp-field-range input::-ms-track {
     background-color: var(--fp-main-control-bg);
     border-radius: 1px;
     margin-top: 3px;
     height: 3px
}

.fp-btn-prim {
     color: inherit;
     background-color: var(--fp-main-light-color);
     border-radius: 2px;
     padding: 3px 6px;
     padding: var(--fp-input-padding);
     cursor: pointer;
     border: none
}

.fp-btn-prim:active {
     background-color: var(--fp-main-light-color)
}

.fp-btn--full {
     width: 100%
}

.fp-chk-icon {
     -ms-transform: rotate(45deg);
     -webkit-transform: rotate(45deg);
     -moz-transform: rotate(45deg);
     transform: rotate(45deg);
     box-sizing: border-box;
     display: block;
     height: 14px;
     margin: 0 5px;
     width: 6px
}

.fp-add-trasp {
     background: none;
     border: none;
     color: var(--fp-font-color);
     cursor: pointer;
     font-size: 1em;
     border-radius: 2px;
     opacity: .75;
     filter: alpha(opacity=75)
}

.fp-add-trasp:hover {
     opacity: 1;
     filter: alpha(opacity=100)
}

.fp-add-trasp:active {
     background-color: rgba(0, 0, 0, .2)
}

.fp-devices-c {
     display: flex;
     align-items: center;
     padding: 2px 3px 3px 3px
}

.fp-devices-c .fp-device-label {
     flex-grow: 2;
     text-align: left;
     margin-right: 10px
}

.fp-devices-c .fp-select {
     flex-grow: 20
}

.fp-devices-c .fp-add-trasp {
     flex-grow: 1;
     margin-left: 5px
}

.fp-category-open,
.fp-block-category.fp-open,
.fp-sm-sector.fp-sm-open {
     background-color: #f1f2f4;
     border-bottom: 1px solid rgba(0, 0, 0, .25)
}

.fp-category-title,
.fp-layer-title,
.fp-block-category .fp-title,
.fp-sm-sector-title {
     font-weight: lighter;
     background-color: var(--fp-secondary-dark-color);
     border-radius: 4px;
     color: var(--fp-primary-color);
     letter-spacing: 1px;
     padding: 9px 10px;
     border-bottom: 1px solid rgba(0, 0, 0, .25);
     text-align: left;
     position: relative;
     cursor: pointer
}

.fp-sm-clear {
     cursor: pointer;
     width: 14px;
     min-width: 14px;
     height: 14px;
     margin-left: 3px
}

.fp-sm-header {
     font-weight: lighter;
     padding: 10px
}

.fp-sm-sector {
     clear: both;
     font-weight: lighter;
     text-align: left
}

.fp-sm-sector-title {
     display: flex;
     align-items: center
}

.fp-sm-sector-caret {
     width: 17px;
     height: 17px;
     min-width: 17px;
     transform: rotate(-90deg)
}

.fp-sm-sector-label {
     margin-left: 5px
}

.fp-sm-sector.fp-sm-open .fp-sm-sector-caret {
     transform: none
}

.fp-sm-properties {
     font-size: var(--fp-font-size);
     color: var(--fp-main-color);
     padding: 10px 5px;
     display: flex;
     flex-wrap: wrap;
     align-items: flex-end;
     box-sizing: border-box;
     width: 100%
}

.fp-sm-label {
     margin: 5px 5px 1px 2px;
     display: flex;
     align-items: center
}

.fp-sm-close-btn,
.fp-sm-preview-file-close {
     display: block;
     font-size: 23px;
     position: absolute;
     cursor: pointer;
     right: 5px;
     top: 0;
     opacity: .7;
     filter: alpha(opacity=70)
}

.fp-sm-close-btn:hover,
.fp-sm-preview-file-close:hover {
     opacity: .9;
     filter: alpha(opacity=90)
}

.fp-sm-field,
.fp-clm-select,
.fp-clm-field {
     width: 100%;
     position: relative
}

.fp-sm-field input,
.fp-clm-select input,
.fp-clm-field input,
.fp-sm-field select,
.fp-clm-select select,
.fp-clm-field select {
     background-color: rgba(0, 0, 0, 0);
     color: rgba(255, 255, 255, .7);
     border: none;
     width: 100%
}

.fp-sm-field input,
.fp-clm-select input,
.fp-clm-field input {
     box-sizing: border-box
}

.fp-sm-field select,
.fp-clm-select select,
.fp-clm-field select {
     position: relative;
     z-index: 1;
     -webkit-appearance: none;
     -moz-appearance: none;
     appearance: none
}

.fp-sm-field select::-ms-expand,
.fp-clm-select select::-ms-expand,
.fp-clm-field select::-ms-expand {
     display: none
}

.fp-sm-field select:-moz-focusring,
.fp-clm-select select:-moz-focusring,
.fp-clm-field select:-moz-focusring {
     color: rgba(0, 0, 0, 0);
     text-shadow: 0 0 0 var(--fp-secondary-light-color)
}

.fp-sm-field input:focus,
.fp-clm-select input:focus,
.fp-clm-field input:focus,
.fp-sm-field select:focus,
.fp-clm-select select:focus,
.fp-clm-field select:focus {
     outline: none
}

.fp-sm-field .fp-sm-unit,
.fp-clm-select .fp-sm-unit,
.fp-clm-field .fp-sm-unit {
     position: absolute;
     right: 10px;
     top: 3px;
     font-size: 10px;
     color: var(--fp-secondary-light-color);
     cursor: pointer
}

.fp-sm-field .fp-clm-sel-arrow,
.fp-clm-select .fp-clm-sel-arrow,
.fp-clm-field .fp-clm-sel-arrow,
.fp-sm-field .fp-sm-int-arrows,
.fp-clm-select .fp-sm-int-arrows,
.fp-clm-field .fp-sm-int-arrows,
.fp-sm-field .fp-sm-sel-arrow,
.fp-clm-select .fp-sm-sel-arrow,
.fp-clm-field .fp-sm-sel-arrow {
     height: 100%;
     width: 9px;
     position: absolute;
     right: 0;
     top: 0;
     cursor: ns-resize
}

.fp-sm-field .fp-sm-sel-arrow,
.fp-clm-select .fp-sm-sel-arrow,
.fp-clm-field .fp-sm-sel-arrow {
     cursor: pointer
}

.fp-sm-field .fp-clm-d-s-arrow,
.fp-clm-select .fp-clm-d-s-arrow,
.fp-clm-field .fp-clm-d-s-arrow,
.fp-sm-field .fp-sm-d-arrow,
.fp-clm-select .fp-sm-d-arrow,
.fp-clm-field .fp-sm-d-arrow,
.fp-sm-field .fp-sm-d-s-arrow,
.fp-clm-select .fp-sm-d-s-arrow,
.fp-clm-field .fp-sm-d-s-arrow,
.fp-sm-field .fp-sm-u-arrow,
.fp-clm-select .fp-sm-u-arrow,
.fp-clm-field .fp-sm-u-arrow {
     position: absolute;
     height: 0;
     width: 0;
     border-left: 3px solid rgba(0, 0, 0, 0);
     border-right: 4px solid rgba(0, 0, 0, 0);
     cursor: pointer
}

.fp-sm-field .fp-sm-u-arrow,
.fp-clm-select .fp-sm-u-arrow,
.fp-clm-field .fp-sm-u-arrow {
     border-bottom: 4px solid var(--fp-secondary-light-color);
     top: 4px
}

.fp-sm-field .fp-clm-d-s-arrow,
.fp-clm-select .fp-clm-d-s-arrow,
.fp-clm-field .fp-clm-d-s-arrow,
.fp-sm-field .fp-sm-d-arrow,
.fp-clm-select .fp-sm-d-arrow,
.fp-clm-field .fp-sm-d-arrow,
.fp-sm-field .fp-sm-d-s-arrow,
.fp-clm-select .fp-sm-d-s-arrow,
.fp-clm-field .fp-sm-d-s-arrow {
     border-top: 4px solid var(--fp-secondary-light-color);
     bottom: 4px
}

.fp-sm-field .fp-clm-d-s-arrow,
.fp-clm-select .fp-clm-d-s-arrow,
.fp-clm-field .fp-clm-d-s-arrow,
.fp-sm-field .fp-sm-d-s-arrow,
.fp-clm-select .fp-sm-d-s-arrow,
.fp-clm-field .fp-sm-d-s-arrow {
     bottom: 7px
}

.fp-sm-field.fp-sm-color,
.fp-sm-color.fp-clm-field,
.fp-sm-field.fp-sm-input,
.fp-sm-input.fp-clm-field,
.fp-sm-field.fp-sm-integer,
.fp-sm-integer.fp-clm-field,
.fp-sm-field.fp-sm-list,
.fp-sm-list.fp-clm-field,
.fp-sm-field.fp-sm-select,
.fp-clm-select,
.fp-sm-select.fp-clm-field {
     background-color: var(--fp-main-control-bg);
     border: 1px solid rgba(0, 0, 0, .1);
     box-shadow: 1px 1px 0 var(--fp-main-light-color);
     color: var(--fp-secondary-light-color);
     border-radius: 2px;
     box-sizing: border-box;
     padding: 0 5px
}

.fp-sm-field.fp-sm-composite,
.fp-sm-composite.fp-clm-select,
.fp-sm-composite.fp-clm-field {
     border-radius: 2px
}

.fp-sm-field.fp-sm-select,
.fp-clm-select,
.fp-sm-select.fp-clm-field {
     padding: 0
}

.fp-sm-field.fp-sm-select select,
.fp-clm-select select,
.fp-sm-select.fp-clm-field select {
     height: 20px
}

.fp-sm-field.fp-sm-select option,
.fp-clm-select option,
.fp-sm-select.fp-clm-field option {
     padding: 3px 0
}

.fp-sm-field.fp-sm-composite,
.fp-sm-composite.fp-clm-select,
.fp-sm-composite.fp-clm-field {
     background-color: var(--fp-secondary-dark-color);
     border: 1px solid rgba(0, 0, 0, .25)
}

.fp-sm-field.fp-sm-list,
.fp-sm-list.fp-clm-select,
.fp-sm-list.fp-clm-field {
     width: auto;
     padding: 0;
     overflow: hidden;
     float: left
}

.fp-sm-field.fp-sm-list input,
.fp-sm-list.fp-clm-select input,
.fp-sm-list.fp-clm-field input {
     display: none
}

.fp-sm-field.fp-sm-list label,
.fp-sm-list.fp-clm-select label,
.fp-sm-list.fp-clm-field label {
     cursor: pointer;
     padding: 5px;
     display: block
}

.fp-sm-field.fp-sm-list .fp-sm-radio:checked+label,
.fp-sm-list.fp-clm-select .fp-sm-radio:checked+label,
.fp-sm-list.fp-clm-field .fp-sm-radio:checked+label {
     background-color: rgba(255, 255, 255, .2)
}

.fp-sm-field.fp-sm-list .fp-sm-icon,
.fp-sm-list.fp-clm-select .fp-sm-icon,
.fp-sm-list.fp-clm-field .fp-sm-icon {
     background-repeat: no-repeat;
     background-position: center;
     text-shadow: none;
     line-height: normal
}

.fp-sm-field.fp-sm-integer select,
.fp-sm-integer.fp-clm-select select,
.fp-sm-integer.fp-clm-field select {
     width: auto;
     padding: 0
}

.fp-sm-list .fp-sm-el {
     float: left;
     border-left: 1px solid var(--fp-main-dark-color)
}

.fp-sm-list .fp-sm-el:first-child {
     border: none
}

.fp-sm-list .fp-sm-el:hover {
     background: var(--fp-main-dark-color)
}

.fp-sm-slider .fp-field-integer {
     flex: 1 1 65px
}

.fp-sm-property {
     box-sizing: border-box;
     float: left;
     width: 50%;
     margin-bottom: 5px;
     padding: 0 5px
}

.fp-sm-property--full,
.fp-sm-property.fp-sm-composite,
.fp-sm-property.fp-sm-file,
.fp-sm-property.fp-sm-list,
.fp-sm-property.fp-sm-stack,
.fp-sm-property.fp-sm-slider,
.fp-sm-property.fp-sm-color {
     width: 100%
}

.fp-sm-property .fp-sm-btn {
     background-color: color-mix(in srgb, var(--fp-main-dark-color), white 13%);
     border-radius: 2px;
     box-shadow: 1px 1px 0 color-mix(in srgb, var(--fp-main-dark-color), white 2%), 1px 1px 0 color-mix(in srgb, var(--fp-main-dark-color), white 17%) inset;
     padding: 5px;
     position: relative;
     text-align: center;
     height: auto;
     width: 100%;
     cursor: pointer;
     color: var(--fp-font-color);
     box-sizing: border-box;
     text-shadow: -1px -1px 0 var(--fp-main-dark-color);
     border: none;
     opacity: .85;
     filter: alpha(opacity=85)
}

.fp-sm-property .fp-sm-btn-c {
     box-sizing: border-box;
     float: left;
     width: 100%
}

.fp-sm-property__text-shadow .fp-sm-layer-preview-cnt::after {
     color: #000;
     content: "T";
     font-weight: 900;
     line-height: 17px;
     padding: 0 4px
}

.fp-sm-preview-file {
     background-color: var(--fp-light-border);
     border-radius: 2px;
     margin-top: 5px;
     position: relative;
     overflow: hidden;
     border: 1px solid color-mix(in srgb, var(--fp-light-border), black 1%);
     padding: 3px 20px
}

.fp-sm-preview-file-cnt {
     background-size: auto 100%;
     background-repeat: no-repeat;
     background-position: center center;
     height: 50px
}

.fp-sm-preview-file-close {
     top: -5px;
     width: 14px;
     height: 14px
}

.fp-sm-layers {
     margin-top: 5px;
     padding: 1px 3px;
     min-height: 30px
}

.fp-sm-layer {
     background-color: rgba(255, 255, 255, .055);
     border-radius: 2px;
     margin: 2px 0;
     padding: 7px;
     position: relative
}

.fp-sm-layer.fp-sm-active {
     background-color: rgba(255, 255, 255, .12)
}

.fp-sm-layer .fp-sm-label-wrp {
     display: flex;
     align-items: center
}

.fp-sm-layer #fp-sm-move {
     height: 14px;
     width: 14px;
     min-width: 14px;
     cursor: grab
}

.fp-sm-layer #fp-sm-label {
     flex-grow: 1;
     overflow: hidden;
     white-space: nowrap;
     text-overflow: ellipsis;
     margin: 0 5px
}

.fp-sm-layer-preview {
     height: 15px;
     width: 15px;
     min-width: 15px;
     margin-right: 5px;
     border-radius: 2px
}

.fp-sm-layer-preview-cnt {
     border-radius: 2px;
     background-color: #fff;
     height: 100%;
     width: 100%;
     background-size: cover !important
}

.fp-sm-layer #fp-sm-close-layer {
     display: block;
     cursor: pointer;
     height: 14px;
     width: 14px;
     min-width: 14px;
     opacity: .5;
     filter: alpha(opacity=50)
}

.fp-sm-layer #fp-sm-close-layer:hover {
     opacity: .8;
     filter: alpha(opacity=80)
}

.fp-sm-stack .fp-sm-properties {
     padding: 5px 0 0
}

.fp-sm-stack #fp-sm-add {
     background: none;
     border: none;
     cursor: pointer;
     outline: none;
     position: absolute;
     right: 0;
     top: -17px;
     opacity: .75;
     padding: 0;
     width: 18px;
     height: 18px
}

.fp-sm-stack #fp-sm-add:hover {
     opacity: 1;
     filter: alpha(opacity=100)
}

.fp-sm-colorp-c {
     height: 100%;
     width: 20px;
     position: absolute;
     right: 0;
     top: 0;
     box-sizing: border-box;
     border-radius: 2px;
     padding: 2px
}

.fp-sm-colorp-c .fp-checker-bg,
.fp-sm-colorp-c .fp-field-colorp-c {
     height: 100%;
     width: 100%;
     border-radius: 1px
}

.fp-sm-color-picker {
     background-color: var(--fp-font-color);
     cursor: pointer;
     height: 16px;
     width: 100%;
     margin-top: -16px;
     box-shadow: 0 0 1px var(--fp-main-dark-color);
     border-radius: 1px
}

.fp-sm-btn-upload #fp-sm-upload {
     left: 0;
     top: 0;
     position: absolute;
     width: 100%;
     opacity: 0;
     cursor: pointer
}

.fp-sm-btn-upload #fp-sm-label {
     padding: 2px 0
}

.fp-sm-layer>#fp-sm-move {
     opacity: .7;
     filter: alpha(opacity=70);
     cursor: move;
     font-size: 12px;
     float: left;
     margin: 0 5px 0 0
}

.fp-sm-layer>#fp-sm-move:hover {
     opacity: .9;
     filter: alpha(opacity=90)
}

.fp-blocks-c {
     display: grid;
     grid-template-columns: 1fr 1fr 1fr;
     justify-content: flex-start;
     padding: 0 10px;
     background-color: var(--fp-primary-bg);
}

.fp-block-categories {
     display: flex;
     flex-direction: column
}

div[data-fp-block-category="structures"] {
     order: 1;
}

div[data-fp-block-category="basic"] {
     order: 2;
}

div[data-fp-block-category="prebuild"] {
     order: 3;
}

div[data-fp-block-category="customer_details"] {
     order: 4;
}

div[data-fp-block-category="purchase_details"] {
     order: 5;
}

.fp-input-holder input {
     color: #121212;
}

.fp-block-category {
     width: 100%
}

.fp-block-category .fp-caret-icon {
     margin-right: 5px
}

.fp-block {
     -moz-user-select: none;
     -khtml-user-select: none;
     -webkit-user-select: none;
     -ms-user-select: none;
     -o-user-select: none;
     user-select: none;
     min-width: 45px;
     min-height: 75px;

     width: 90px;

     padding: 10px;
     box-sizing: border-box;
     cursor: all-scroll;
     font-size: 11px;
     font-weight: lighter;
     text-align: center;
     display: flex;
     flex-direction: column;
     justify-content: space-between;
     color: var(--fp-main-color);
     border: 1px solid var(--fp-primary-border-color);
     border-radius: var(--fp-main-border-radius);
     margin: 5px auto;
     transition: all .2s ease 0s;
}

.fp-block:hover {
     box-shadow: 0 3px 4px 0 rgba(0, 0, 0, .15)
}

.fp-block svg {
     fill: currentColor;
     max-height: 25px;
}

.fp-block__media {
     pointer-events: none
}

.fp-block-svg {
     width: 54px;
     fill: currentColor
}

.fp-block-svg-path {
     fill: currentColor
}

.fp-block.fa {
     font-size: 2em;
     line-height: 1;
     padding: 11px
}

.fp-block-label {
     line-height: normal;
     font-size: .65rem;
     font-weight: normal;
     font-family: Helvetica, sans-serif;
     overflow: hidden;
     text-overflow: ellipsis;
     pointer-events: none
}

.fp-block.fp-bdrag {
     width: auto;
     padding: 0
}

.fp-selected-parent {
     border: 1px solid var(--fp-color-yellow)
}

.fp-opac50 {
     opacity: .5;
     filter: alpha(opacity=50)
}

.fp-layer {
     font-weight: lighter;
     text-align: left;
     position: relative;
     font-size: var(--fp-font-size);
     display: grid
}

.fp-layer-item {
     display: flex;
     align-items: center;
     justify-content: space-between;
     padding: 5px 10px;
     border-bottom: 1px solid var(--fp-main-dark-color);
     background-color: var(--fp-secondary-dark-color);
     gap: var(--fp-flex-item-gap);
     cursor: pointer
}

.fp-layer-item-left,
.fp-layer-item-right {
     display: flex;
     align-items: center;
     gap: var(--fp-flex-item-gap)
}

.fp-layer-item-left {
     width: 100%
}

.fp-layer-hidden {
     opacity: .55;
     filter: alpha(opacity=55)
}

.fp-layer-vis {
     box-sizing: content-box;
     cursor: pointer;
     z-index: 1
}

.fp-layer-vis-on,
.fp-layer-vis-off {
     display: flex;
     width: 13px
}

.fp-layer-vis-off {
     display: none
}

.fp-layer-vis.fp-layer-off .fp-layer-vis-on {
     display: none
}

.fp-layer-vis.fp-layer-off .fp-layer-vis-off {
     display: flex
}

.fp-layer-caret {
     width: 15px;
     cursor: pointer;
     box-sizing: content-box;
     transform: rotate(90deg);
     display: flex;
     opacity: .7;
     filter: alpha(opacity=70)
}

.fp-layer-caret:hover {
     opacity: 1;
     filter: alpha(opacity=100)
}

.fp-layer.open>.fp-layer-item .fp-layer-caret {
     transform: rotate(180deg)
}

.fp-layer-title {
     padding: 0;
     display: flex;
     align-items: center;
     background-color: rgba(0, 0, 0, 0) !important;
     border-bottom: none
}

.fp-layer-title-inn {
     align-items: center;
     position: relative;
     display: flex;
     gap: var(--fp-flex-item-gap)
}

.fp-layer-title-c {
     width: 100%
}

.fp-layer__icon {
     display: block;
     width: 100%;
     max-width: 15px;
     max-height: 15px;
     padding-left: 5px
}

.fp-layer__icon svg {
     fill: currentColor
}

.fp-layer-name {
     display: inline-block;
     box-sizing: content-box;
     overflow: hidden;
     white-space: nowrap;
     max-width: 170px;
     height: auto
}

.fp-layer-name--no-edit {
     text-overflow: ellipsis
}

.fp-layer>.fp-layer-children {
     display: none
}

.fp-layer.open>.fp-layer-children {
     display: block
}

.fp-layer-no-chld>.fp-layer-title-inn>.fp-layer-caret {
     visibility: hidden
}

.fp-layer-move {
     display: flex;
     width: 13px;
     box-sizing: content-box;
     cursor: move
}

.fp-layer.fp-selected .fp-layer-item {
     background-color: var(--fp-main-light-color)
}

.fp-layers {
     position: relative;
     height: 100%
}

.fp-layers #fp-placeholder {
     width: 100%;
     position: absolute
}

.fp-layers #fp-placeholder #fp-plh-int {
     height: 100%;
     padding: 1px
}

.fp-layers #fp-placeholder #fp-plh-int.fp-insert {
     background-color: var(--fp-color-green)
}

#fp-clm-add-tag,
.fp-clm-tags-btn {
     background-color: rgba(255, 255, 255, .15);
     border-radius: 2px;
     padding: 3px;
     margin-right: 3px;
     border: 1px solid rgba(0, 0, 0, .15);
     width: 24px;
     height: 24px;
     box-sizing: border-box;
     cursor: pointer
}

.fp-clm-tags-btn svg {
     fill: currentColor;
     display: block
}

.fp-clm-header {
     display: flex;
     align-items: center;
     margin: 7px 0
}

.fp-clm-header-status {
     flex-shrink: 1;
     margin-left: auto
}

.fp-clm-tag {
     display: flex;
     overflow: hidden;
     align-items: center;
     border-radius: 3px;
     margin: 0 3px 3px 0;
     padding: 5px;
     cursor: default
}

.fp-clm-tag-status,
.fp-clm-tag-close {
     width: 12px;
     height: 12px;
     flex-shrink: 1
}

.fp-hovered {
     border-color: red;
}

.fp-clm-tag-status svg,
.fp-clm-tag-close svg {
     vertical-align: middle;
     fill: currentColor
}

.fp-clm-sels-info {
     margin: 7px 0;
     text-align: left
}

.fp-clm-sel-id {
     font-size: .9em;
     opacity: .5;
     filter: alpha(opacity=50)
}

.fp-clm-label-sel {
     float: left;
     padding-right: 5px
}

.fp-clm-tags {
     font-size: var(--fp-font-size);
     padding: 10px 5px
}

.fp-clm-tags #fp-clm-sel {
     padding: 7px 0;
     float: left
}

.fp-clm-tags #fp-clm-sel {
     font-style: italic;
     margin-left: 5px
}

.fp-clm-tags #fp-clm-tags-field {
     clear: both;
     padding: 5px;
     margin-bottom: 5px;
     display: flex;
     flex-wrap: wrap
}

.fp-clm-tags #fp-clm-tags-c {
     display: flex;
     flex-wrap: wrap;
     vertical-align: top;
     overflow: hidden
}

.fp-clm-tags #fp-clm-new {
     color: var(--fp-font-color);
     padding: var(--fp-padding-elem-classmanager);
     display: none
}

.fp-clm-tags #fp-clm-close {
     opacity: .85;
     filter: alpha(opacity=85);
     font-size: 20px;
     line-height: 0;
     cursor: pointer;
     color: rgba(255, 255, 255, .9)
}

.fp-clm-tags #fp-clm-close:hover {
     opacity: 1;
     filter: alpha(opacity=100)
}

.fp-clm-tags #fp-clm-checkbox {
     color: rgba(255, 255, 255, .9);
     vertical-align: middle;
     cursor: pointer;
     font-size: 9px
}

.fp-clm-tags #fp-clm-tag-label {
     flex-grow: 1;
     text-overflow: ellipsis;
     overflow: hidden;
     padding: 0 3px;
     cursor: text
}

.fp-mdl-container {
     font-family: var(--fp-main-font);
     overflow-y: auto;
     position: fixed;
     background-color: rgba(0, 0, 0, .5);
     display: flex;
     top: 0;
     left: 0;
     right: 0;
     bottom: 0;
     z-index: 100
}

.fp-mdl-dialog {
     text-shadow: -1px -1px 0 rgba(0, 0, 0, .05);
     animation: fp-slide-down .215s;
     margin: auto;
     max-width: 850px;
     width: 90%;
     border-radius: 3px;
     font-weight: lighter;
     position: relative;
     z-index: 2
}

.fp-mdl-title {
     font-size: 1rem
}

.fp-mdl-btn-close {
     position: absolute;
     right: 15px;
     top: 5px
}

.fp-mdl-active .fp-mdl-dialog {
     animation: fp-mdl-slide-down .216s
}

.fp-mdl-header,
.fp-mdl-content {
     padding: 10px 15px;
     clear: both
}

.fp-mdl-header {
     position: relative;
     border-bottom: 1px solid var(--fp-main-dark-color);
     padding: 15px 15px 7px
}

.fp-export-dl::after {
     content: "";
     clear: both;
     display: block;
     margin-bottom: 10px
}

.fp-dropzone {
     display: none;
     opacity: 0;
     position: absolute;
     top: 0;
     left: 0;
     z-index: 11;
     width: 100%;
     height: 100%;
     transition: opacity .25s;
     pointer-events: none
}

.fp-dropzone-active .fp-dropzone {
     display: block;
     opacity: 1
}

.fp-am-assets {
     height: 290px;
     overflow: auto;
     clear: both;
     display: flex;
     flex-wrap: wrap;
     align-items: flex-start;
     align-content: flex-start
}

.fp-am-assets-header {
     padding: 5px
}

.fp-am-add-asset .fp-am-add-field {
     width: 70%;
     float: left
}

.fp-am-add-asset button {
     width: 25%;
     float: right
}

.cont {
     background-color: rgb(245, 248, 250);
     padding-top: 100px;
     padding-right: 10px;
     padding-bottom: 100px;
     padding-left: 10px;
}

.fp-am-preview-cont {
     position: relative;
     height: 70px;
     width: 30%;
     background-color: var(--fp-main-color);
     border-radius: 2px;
     float: left;
     overflow: hidden
}

.fp-am-preview {
     position: absolute;
     background-position: center center;
     background-size: cover;
     background-repeat: no-repeat;
     height: 100%;
     width: 100%;
     z-index: 1
}

.fp-am-preview-bg {
     opacity: .5;
     filter: alpha(opacity=50);
     position: absolute;
     height: 100%;
     width: 100%;
     z-index: 0
}

.fp-am-dimensions {
     opacity: .5;
     filter: alpha(opacity=50);
     font-size: 10px
}

.fp-am-meta {
     width: 70%;
     float: left;
     font-size: 12px;
     padding: 5px 0 0 5px;
     box-sizing: border-box
}

.fp-am-meta>div {
     margin-bottom: 5px
}

.fp-am-close {
     cursor: pointer;
     position: absolute;
     right: 5px;
     top: 0;
     display: none
}

.fp-am-asset {
     border-bottom: 1px solid color-mix(in srgb, var(--fp-main-dark-color), black 3%);
     padding: 5px;
     cursor: pointer;
     position: relative;
     box-sizing: border-box;
     width: 100%
}

.fp-am-asset:hover .fp-am-close {
     display: block
}

.fp-am-highlight {
     background-color: var(--fp-main-light-color)
}

.fp-am-assets-cont {
     background-color: var(--fp-secondary-dark-color);
     border-radius: 3px;
     box-sizing: border-box;
     padding: 10px;
     width: 45%;
     float: right;
     height: 325px;
     overflow: hidden
}

.fp-am-file-uploader {
     width: 55%;
     float: left
}

.fp-am-file-uploader>form {
     background-color: var(--fp-secondary-dark-color);
     border: 2px dashed;
     border-radius: 3px;
     position: relative;
     text-align: center;
     margin-bottom: 15px
}

.fp-am-file-uploader>form.fp-am-hover {
     border: 2px solid var(--fp-color-green);
     color: color-mix(in srgb, var(--fp-color-green), white 5%)
}

.fp-am-file-uploader>form.fp-am-disabled {
     border-color: red
}

.fp-am-file-uploader>form #fp-am-uploadFile {
     opacity: 0;
     filter: alpha(opacity=0);
     padding: var(--fp-upload-padding);
     width: 100%;
     box-sizing: border-box
}

.fp-am-file-uploader #fp-am-title {
     position: absolute;
     padding: var(--fp-upload-padding);
     width: 100%
}

.fp-cm-editor-c {
     float: left;
     box-sizing: border-box;
     width: 50%
}

.fp-cm-editor-c .CodeMirror {
     height: 450px
}

.fp-cm-editor {
     font-size: 12px
}

.fp-cm-editor#fp-cm-htmlmixed {
     padding-right: 10px;
     border-right: 1px solid var(--fp-main-dark-color)
}

.fp-cm-editor#fp-cm-htmlmixed #fp-cm-title {
     color: #a97d44
}

.fp-cm-editor#fp-cm-css {
     padding-left: 10px
}

.fp-cm-editor#fp-cm-css #fp-cm-title {
     color: #ddca7e
}

.fp-cm-editor #fp-cm-title {
     background-color: var(--fp-main-control-bg);
     font-size: 12px;
     padding: 5px 10px 3px;
     text-align: right
}

.fp-rte-toolbar {
     position: absolute;
     z-index: 10
}

.fp-rte-toolbar-ui {
     border: 1px solid var(--fp-main-dark-color);
     border-radius: 3px
}

.fp-rte-actionbar {
     display: flex
}

.fp-rte-action {
     display: flex;
     align-items: center;
     justify-content: center;
     padding: 5px;
     width: 25px;
     border-right: 1px solid var(--fp-main-dark-color);
     text-align: center;
     cursor: pointer;
     outline: none
}

.fp-rte-action:last-child {
     border-right: none
}

.fp-rte-action:hover {
     background-color: var(--fp-main-light-color)
}

.fp-rte-active {
     background-color: var(--fp-main-light-color)
}

.fp-rte-disabled {
     color: var(--fp-main-light-color);
     cursor: not-allowed
}

.fp-rte-disabled:hover {
     background-color: unset
}

.fp-editor-cont .sp-hue,
.fp-editor-cont .sp-slider {
     cursor: row-resize
}

.fp-editor-cont .sp-color,
.fp-editor-cont .sp-dragger {
     cursor: crosshair
}

.fp-editor-cont .sp-alpha-inner,
.fp-editor-cont .sp-alpha-handle {
     cursor: col-resize
}

.fp-editor-cont .sp-hue {
     left: 90%
}

.fp-editor-cont .sp-color {
     right: 15%
}

.fp-editor-cont .sp-container {
     border: 1px solid var(--fp-main-dark-color);
     box-shadow: 0 0 7px var(--fp-main-dark-color);
     border-radius: 3px
}

.fp-editor-cont .sp-picker-container {
     border: none
}

.fp-editor-cont .colpick_dark .colpick_color {
     outline: 1px solid var(--fp-main-dark-color)
}

.fp-editor-cont .sp-cancel,
.fp-editor-cont .sp-cancel:hover {
     bottom: -8px;
     color: #777 !important;
     font-size: 25px;
     left: 0;
     position: absolute;
     text-decoration: none
}

.fp-editor-cont .sp-alpha-handle {
     background-color: #ccc;
     border: 1px solid #555;
     width: 4px
}

.fp-editor-cont .sp-color,
.fp-editor-cont .sp-hue {
     border: 1px solid #333
}

.fp-editor-cont .sp-slider {
     background-color: #ccc;
     border: 1px solid #555;
     height: 3px;
     left: -4px;
     width: 22px
}

.fp-editor-cont .sp-dragger {
     background: rgba(0, 0, 0, 0);
     box-shadow: 0 0 0 1px #111
}

.fp-editor-cont .sp-button-container {
     float: none;
     width: 100%;
     position: relative;
     text-align: right
}

.fp-editor-cont .sp-container button,
.fp-editor-cont .sp-container button:hover,
.fp-editor-cont .sp-container button:active {
     background: var(--fp-main-dark-color);
     border-color: var(--fp-main-dark-color);
     color: var(--fp-font-color);
     text-shadow: none;
     box-shadow: none;
     padding: 3px 5px
}

.fp-editor-cont .sp-palette-container {
     border: none;
     float: none;
     margin: 0;
     padding: 5px 10px 0
}

.fp-editor-cont .sp-palette .sp-thumb-el,
.fp-editor-cont .sp-palette .sp-thumb-el:hover {
     border: 1px solid rgba(0, 0, 0, .9)
}

.fp-editor-cont .sp-palette .sp-thumb-el:hover,
.fp-editor-cont .sp-palette .sp-thumb-el.sp-thumb-active {
     border-color: rgba(0, 0, 0, .9)
}

.fp-hidden {
     display: none
}

.template-select .carousel-control-prev-icon,
.template-select .carousel-control-next-icon {
     filter: invert(1);
}

.template-select .editor-wrapper .preview-wrapper .preview {
     margin-bottom: 0 !important;
}

@keyframes fp-slide-down {
     0% {
          transform: translate(0, -3rem);
          opacity: 0
     }

     100% {
          transform: translate(0, 0);
          opacity: 1
     }
}

@keyframes fp-slide-up {
     0% {
          transform: translate(0, 0);
          opacity: 1
     }

     100% {
          transform: translate(0, -3rem);
          opacity: 0
     }
}

.cm-s-hopscotch span.cm-error {
     color: #fff
}