// For Mobile Phones
@media (max-width:481px) {
  /* Start - Price and Customer Search Input Container */
  .fp-grid-cotainer {
    grid-template-columns: minmax(140px, auto) 1fr !important;
  }
  /* End - Price and Customer Search Input Container */

  /* Start - Add New Customer  */
  .grid-container.add-new-customer {
    grid-template-columns: auto;

    .grid-item {
      margin-top: 0 !important;
    }

    .add-new-customer-label {
      height: 25px;
    }
  }
  /* End - Add New Customer  */

  /* Start - Subscription Settings  */
  .refund-reason-header {
    justify-content: flex-start !important;
    gap: 10px;
  }
  /* End - Subscription Settings  */

  /* Start - Price Amount  */
  .form-group-amount {
    align-items: flex-start !important;
    flex-direction: column !important;
    gap: 5px !important;

    .fp-ex-vat {
      margin-left: 0 !important;
    }
  }
  /* End - Price Amount  */

  /* Start - Corporate Accounts  */
  .corporate-account-detail-grid {
    grid-template-columns: 0 140px 1fr !important;
  }
  /* End - Corporate Accounts  */

  .tab-sm .nav-tabs {
    justify-content: center !important;
  }
}

// For Tablets
@media (max-width:961px) {
  /* Start - top-nav-bar-component */
  .user-group {
    width: 100% !important;
  }

  .nav-top-element .dropdown-profile {
    display: none !important;
  }
  /* End - top-nav-bar-component */

  /* Start - rule-overview-component */
  .price-btns-wrapper {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  /* End - rule-overview-component */

  .fp-wrapper-dates {
    flex-wrap: wrap !important;
    margin: 10px auto;
    align-items: center;
    justify-content: center;
  }
}

@media (max-width:768px) {
  /* Start - top-nav-bar-component */
  .nav-top-element #dropdown-animated {
    right: -61px !important;
  }
  /* End - top-nav-bar-component */

  /* Start - Coupon Codes */
  .coupon-code-container {
    justify-content: center !important;

    & > div {
      padding: 0;

      button {
        padding: 0;
      }
    }
  }

  .coupon-code-content {
    padding: 0 !important;
  }
  /* End - Coupon Codes */

  /* Start - Deliveries */
  .fp-batch-delivery-wrapper {
    .fp-wrapper {
      flex-direction: column;
      align-items: center !important;

      .fp-custom-post-batch,
      .fp-custom-upload {
        margin: 0 !important;
      }
    }
  }

  /* End - Deliveries */
}

// For Laptops/Desktop
@media (max-width:1025px) {}

// For Hi-Res Laptops
@media (max-width:1281px) {}
