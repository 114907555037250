:root {
     --fp_editor_width: 50%;
}

.sqd-designer {
     height: 100vh;
     max-height: 600px;

     .sqd-designer-angular {
          width: 100vw;
          height: 100vh;
          max-height: 600px;
          box-shadow: 0 0 8px rgba(0, 0, 0, 0.15);

          .sqd-layout-desktop,
          .sqd-layout-mobile {
               border-radius: var(--fp_main_border_radius, 6px);

               .sqd-smart-editor {
                    width: var(--fp_editor_width, 50%);
                    background-color: #ffffff;
                    border-radius: 0px 8px 8px 8px;
                    overflow-x: auto;

                    .sqd-step-editor {
                         padding: 15px 20px 10px;
                    }
               }

               .sqd-smart-editor-toggle {
                    right: var(--fp_editor_width, 50%);
               }
          }

          .sqd-layout-mobile {
               .sqd-smart-editor {
                    left: unset;
               }

               .sqd-smart-editor-toggle {
                    left: unset;
               }
          }

          .sqd-toolbox {
               width: 160px;

               .sqd-toolbox-filter {
                    width: auto;
               }

               .sqd-scrollbox {
                    .sqd-scrollbox-body {
                         right: 0;
                    }
               }
          }

          .sqd-smart-editor-toggle.sqd-collapsed {
               right: 9px;
               top: 9px;
               border-radius: 11px;
               width: 50px;
               height: 50px;
          }

          [class*="sqd-type-jb-ca-"] {
               .sqd-step-task-rect {
                    fill: aquamarine;
               }
          }

          .sqd-step-icon-circle {
               fill: #fff;
               stroke: #c3c3c3;
               filter: drop-shadow(0 1.5px 1.5px rgba(0, 0, 0, 0.15));
               stroke-width: 1;
          }

          .sqd-selected {
               stroke: #ed4800;
          }

          [class^="sqd-step-"] {
               cursor: grab;
               cursor: -webkit-grab;
               cursor: -moz-grab;

               &.sqd-disabled {
                    cursor: grabbing;
                    cursor: -webkit-grabbing;
                    cursor: -moz-grabbing;
               }
          }
     }
}
