/*******************
Width
*******************/

html body .w-25 {
     width: 25%;
}

html body .w-30 {
     width: 30%;
}

html body .w-40 {
     width: 40%;
}

html body .w-50 {
     width: 50%;
}

html body .w-60 {
     width: 60%;
}

html body .w-70 {
     width: 70%;
}

html body .w-75 {
     width: 50%;
}

html body .w-100 {
     width: 100%;
}

/*******************
Paddings
*******************/

html body .p-0 {
     padding: 0px;
}

html body .p-10 {
     padding: 10px;
}

html body .p-20 {
     padding: 20px;
}

html body .p-30 {
     padding: 30px;
}

html body .p-l-0 {
     padding-left: 0px;
}

html body .p-l-10 {
     padding-left: 10px;
}

html body .p-l-15 {
     padding-left: 15px;
}

html body .p-l-20 {
     padding-left: 20px;
}

html body .p-r-0 {
     padding-right: 0px;
}

html body .p-r-10 {
     padding-right: 10px;
}

html body .p-r-15 {
     padding-right: 15px;
}

html body .p-r-20 {
     padding-right: 20px;
}

html body .p-r-30 {
     padding-right: 30px;
}

html body .p-r-40 {
     padding-right: 40px;
}

html body .p-t-0 {
     padding-top: 0px;
}

html body .p-t-5 {
     padding-top: 5px;
}

html body .p-t-10 {
     padding-top: 10px;
}

html body .p-t-20 {
     padding-top: 20px;
}

html body .p-t-30 {
     padding-top: 30px;
}

html body .p-b-0 {
     padding-bottom: 0px;
}

html body .p-b-5 {
     padding-bottom: 5px;
}

html body .p-b-10 {
     padding-bottom: 10px;
}

html body .p-b-20 {
     padding-bottom: 20px;
}

html body .p-b-30 {
     padding-bottom: 30px;
}

html body .p-b-40 {
     padding-bottom: 40px;
}

/*******************
Margin
*******************/

html body .m-a {
     margin-left: auto;
     margin-right: auto;
}

html body .m-0 {
     margin: 0px;
}

html body .m-l-5 {
     margin-left: 5px;
}

html body .m-l-10 {
     margin-left: 10px;
}

html body .m-l-15 {
     margin-left: 15px;
}

html body .m-l-20 {
     margin-left: 20px;
}

html body .m-l-30 {
     margin-left: 30px;
}

html body .m-l-38 {
     margin-left: 38px;
}

html body .m-l-40 {
     margin-left: 40px;
}

html body .m-r-5 {
     margin-right: 5px;
}

html body .m-r-10 {
     margin-right: 10px;
}

html body .m-r-15 {
     margin-right: 15px;
}

html body .m-r-20 {
     margin-right: 20px;
}

html body .m-r-30 {
     margin-right: 30px;
}

html body .m-r-40 {
     margin-right: 40px;
}

html body .m-t-20 {
     margin-top: 20px;
}

html body .m-t-30 {
     margin-top: 30px;
}

html body .m-t-40 {
     margin-top: 40px;
}

html body .m-b-0 {
     margin-bottom: 0px;
}

html body .m-b-5 {
     margin-bottom: 5px;
}

html body .m-b-10 {
     margin-bottom: 10px;
}

html body .m-b-15 {
     margin-bottom: 15px;
}

html body .m-b-20 {
     margin-bottom: 20px;
}

html body .m-b-30 {
     margin-bottom: 30px;
}

html body .m-b-40 {
     margin-bottom: 40px;
}

/*******************
font weight
*******************/

html body .font-bold {
     font-weight: 700;
}

html body .font-normal {
     font-weight: normal;
}

html body .font-light {
     font-weight: 300;
}

html body .font-medium {
     font-weight: 500;
}

html body .font-bolder {
     font-weight: 600;
}

html body .font-15 {
     font-size: 16px;
}

html body .font-16 {
     font-size: 16px;
}

html body .font-13 {
     font-size: 13px;
}

html body .font-14 {
     font-size: 14px;
}

html body .font-10 {
     font-size: 10px;
}

html body .font-18 {
     font-size: 18px;
}

html body .font-20 {
     font-size: 20px;
}

.h-200 {
     min-height: 200px;
}

.gap-1 {
     gap: 0.25rem;
}

.gap-2 {
     gap: 0.5rem;
}

.gap-3 {
     gap: 1rem;
}

.flex-1 {
     flex: 1;
}

h1,
.h1 {
     font-size: 1rem;
}

h2,
.h2 {
     font-size: 1rem;
}

h3,
.h3 {
     font-size: 1rem;
}

h4,
.h4 {
     font-size: 1rem;
}

h5,
.h5 {
     font-size: 1rem;
}

h6,
.h6 {
     font-size: 1rem;
}

.align-items-center {
     align-items: center;
}

.align-items-base {
     align-items: baseline !important;
}

.fd-row {
     flex-direction: row;
}

.fd-column {
     flex-direction: column;
}

hr {
     color: #b9b7b7;
}

.item-err {
     color: red;
     grid-column: 2;
     margin: 0;
     font-size: 0.75rem !important;
}

.text-left {
     text-align: left;
}

.color-main {
     color: var(--fp_main_color);
}

.modal-full {
     --bs-modal-width: 95%;
}

@media screen and (min-width: 1200px) {

     h1,
     .h1,
     h1>span {
          font-size: 1rem;
     }

     h2,
     .h2,
     h2>span {
          font-size: 1.25rem;
     }

     h3,
     .h3,
     h3>span {
          font-size: 1.1rem;
          line-height: 1;
     }

     h4,
     .h4,
     h4>span {
          font-size: .85rem;
     }

     h5,
     .h5,
     h5>span {
          font-size: .75rem;
     }

     h6,
     .h6,
     h6>span {
          font-size: 0.9rem;
     }
}