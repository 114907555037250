/* BUTTON  */
.btn {
     border-radius: var(--fp_main_border_radius, 6px);
     display: flex;
     align-items: center;
     gap: 5px;
}

.btn i {
     font-size: 0.7rem;
}

button.btn[class*="btn-outline-"],
a.btn[class*="btn-outline-"],
.fp-btn-delete {
     height: var(--fp_btn_outline_height, 30px);
}

button.btn {
     height: var(--fp_btn_common_height, 34px);
     padding: 0 10px;
     justify-content: center;
}

button.btn,
a.btn {
     transition: unset;
}

button.btn.btn-sm {
     height: calc(var(--fp_btn_common_height, 34px) - 5px);
     font-size: var(--fp_btn_sm_font_size, 0.8rem);
     font-weight: 500;
}

a.btn {
     justify-content: center;
     padding: 3px 10px;
}

.btn-group-xs>.btn,
.btn-xs,
.btn-xs span {
     padding: 1px 5px;
     font-size: var(--fp_btn_xs_font_size, 0.75rem) !important;
     font-weight: 500;
     line-height: 1;
     border-radius: var(--fp_main_border_radius, 6px);
}

.btn-w-m {
     min-width: 120px;
}

.btn-primary.btn-outline {
     color: var(--fp_main_color);
}

.btn-success.btn-outline {
     color: #1c84c6;
}

.btn-info.btn-outline {
     color: #23c6c8;
}

.btn-warning.btn-outline {
     color: #f8ac59;
}

.btn-danger.btn-outline {
     color: #ed5565;
}

.btn-primary.btn-outline:hover,
.btn-success.btn-outline:hover,
.btn-info.btn-outline:hover,
.btn-warning.btn-outline:hover,
.btn-danger.btn-outline:hover {
     color: #fff;
}

.btn-primary {
     background-color: var(--fp_main_color);
     border-color: var(--fp_main_color);
     color: #ffffff;
}

.btn-primary:hover,
.btn-primary:focus,
.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary,
.btn-primary:active:focus,
.btn-primary:active:hover,
.btn-primary.active:hover,
.btn-primary.active:focus {
     background-color: #18a689;
     border-color: #18a689;
     color: #ffffff;
}

.btn-primary:active,
.btn-primary.active,
.open .dropdown-toggle.btn-primary {
     background-image: none;
}

.btn-primary.disabled,
.btn-primary.disabled:hover,
.btn-primary.disabled:focus,
.btn-primary.disabled:active,
.btn-primary.disabled.active,
.btn-primary[disabled],
.btn-primary[disabled]:hover,
.btn-primary[disabled]:focus,
.btn-primary[disabled]:active,
.btn-primary.active[disabled],
fieldset[disabled] .btn-primary,
fieldset[disabled] .btn-primary:hover,
fieldset[disabled] .btn-primary:focus,
fieldset[disabled] .btn-primary:active,
fieldset[disabled] .btn-primary.active {
     background-color: #1dc5a3;
     border-color: #1dc5a3;
}

.btn-success {
     background-color: #1c84c6;
     border-color: #1c84c6;
     color: #ffffff;
}

.btn-success:hover,
.btn-success:focus,
.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success,
.btn-success:active:focus,
.btn-success:active:hover,
.btn-success.active:hover,
.btn-success.active:focus {
     background-color: #1a7bb9;
     border-color: #1a7bb9;
     color: #ffffff;
}

.btn-success:active,
.btn-success.active,
.open .dropdown-toggle.btn-success {
     background-image: none;
}

.btn-success.disabled,
.btn-success.disabled:hover,
.btn-success.disabled:focus,
.btn-success.disabled:active,
.btn-success.disabled.active,
.btn-success[disabled],
.btn-success[disabled]:hover,
.btn-success[disabled]:focus,
.btn-success[disabled]:active,
.btn-success.active[disabled],
fieldset[disabled] .btn-success,
fieldset[disabled] .btn-success:hover,
fieldset[disabled] .btn-success:focus,
fieldset[disabled] .btn-success:active,
fieldset[disabled] .btn-success.active {
     background-color: #1f90d8;
     border-color: #1f90d8;
}

.btn-info {
     background-color: #23c6c8;
     border-color: #23c6c8;
     color: #ffffff;
}

.btn-info:hover,
.btn-info:focus,
.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info,
.btn-info:active:focus,
.btn-info:active:hover,
.btn-info.active:hover,
.btn-info.active:focus {
     background-color: #21b9bb;
     border-color: #21b9bb;
     color: #ffffff;
}

.btn-info:active,
.btn-info.active,
.open .dropdown-toggle.btn-info {
     background-image: none;
}

.btn-info.disabled,
.btn-info.disabled:hover,
.btn-info.disabled:focus,
.btn-info.disabled:active,
.btn-info.disabled.active,
.btn-info[disabled],
.btn-info[disabled]:hover,
.btn-info[disabled]:focus,
.btn-info[disabled]:active,
.btn-info.active[disabled],
fieldset[disabled] .btn-info,
fieldset[disabled] .btn-info:hover,
fieldset[disabled] .btn-info:focus,
fieldset[disabled] .btn-info:active,
fieldset[disabled] .btn-info.active {
     background-color: #26d7d9;
     border-color: #26d7d9;
}

.btn-default {
     background-color: #c2c2c2;
     border-color: #c2c2c2;
     color: #ffffff;
}

.btn-default:hover,
.btn-default:focus,
.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default,
.btn-default:active:focus,
.btn-default:active:hover,
.btn-default.active:hover,
.btn-default.active:focus {
     background-color: #bababa;
     border-color: #bababa;
     color: #ffffff;
}

.btn-default:active,
.btn-default.active,
.open .dropdown-toggle.btn-default {
     background-image: none;
}

.btn-default.disabled,
.btn-default.disabled:hover,
.btn-default.disabled:focus,
.btn-default.disabled:active,
.btn-default.disabled.active,
.btn-default[disabled],
.btn-default[disabled]:hover,
.btn-default[disabled]:focus,
.btn-default[disabled]:active,
.btn-default.active[disabled],
fieldset[disabled] .btn-default,
fieldset[disabled] .btn-default:hover,
fieldset[disabled] .btn-default:focus,
fieldset[disabled] .btn-default:active,
fieldset[disabled] .btn-default.active {
     background-color: #cccccc;
     border-color: #cccccc;
}

.btn-warning {
     background-color: #f8ac59;
     border-color: #f8ac59;
     color: #ffffff;
}

.btn-warning:hover,
.btn-warning:focus,
.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning,
.btn-warning:active:focus,
.btn-warning:active:hover,
.btn-warning.active:hover,
.btn-warning.active:focus {
     background-color: #f7a54a;
     border-color: #f7a54a;
     color: #ffffff;
}

.btn-warning:active,
.btn-warning.active,
.open .dropdown-toggle.btn-warning {
     background-image: none;
}

.btn-warning.disabled,
.btn-warning.disabled:hover,
.btn-warning.disabled:focus,
.btn-warning.disabled:active,
.btn-warning.disabled.active,
.btn-warning[disabled],
.btn-warning[disabled]:hover,
.btn-warning[disabled]:focus,
.btn-warning[disabled]:active,
.btn-warning.active[disabled],
fieldset[disabled] .btn-warning,
fieldset[disabled] .btn-warning:hover,
fieldset[disabled] .btn-warning:focus,
fieldset[disabled] .btn-warning:active,
fieldset[disabled] .btn-warning.active {
     background-color: #f9b66d;
     border-color: #f9b66d;
}

.btn-danger,
.fp-bg-btn-danger {
     background-color: #ed5565;
     border-color: #ed5565;
     color: #ffffff;
}

.fp-btn-price-tree,
.fp-bg-btn-danger {
     min-width: 100px;
}

.fp-bg-btn-danger:hover {
     background-color: #ffffff;
     border-color: #ec4758;
     color: #ed5565;
}

.btn-danger:hover,
.btn-danger:focus,
.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger,
.btn-danger:active:focus,
.btn-danger:active:hover,
.btn-danger.active:hover,
.btn-danger.active:focus {
     background-color: #ec4758;
     border-color: #ec4758;
     color: #ec4758;
     background-color: #ffffff;
     font-weight: 600;
}

.btn-danger:active,
.btn-danger.active,
.open .dropdown-toggle.btn-danger {
     background-image: none;
}

.btn-danger.disabled,
.btn-danger.disabled:hover,
.btn-danger.disabled:focus,
.btn-danger.disabled:active,
.btn-danger.disabled.active,
.btn-danger[disabled],
.btn-danger[disabled]:hover,
.btn-danger[disabled]:focus,
.btn-danger[disabled]:active,
.btn-danger.active[disabled],
fieldset[disabled] .btn-danger,
fieldset[disabled] .btn-danger:hover,
fieldset[disabled] .btn-danger:focus,
fieldset[disabled] .btn-danger:active,
fieldset[disabled] .btn-danger.active {
     background-color: #ef6776;
     border-color: #ef6776;
}

.btn-link {
     color: inherit;
}

.btn-link:hover,
.btn-link:focus,
.btn-link:active,
.btn-link.active,
.open .dropdown-toggle.btn-link {
     color: var(--fp_main_color);
     text-decoration: none;
}

.btn-link:active,
.btn-link.active,
.open .dropdown-toggle.btn-link {
     background-image: none;
}

.btn-link.disabled,
.btn-link.disabled:hover,
.btn-link.disabled:focus,
.btn-link.disabled:active,
.btn-link.disabled.active,
.btn-link[disabled],
.btn-link[disabled]:hover,
.btn-link[disabled]:focus,
.btn-link[disabled]:active,
.btn-link.active[disabled],
fieldset[disabled] .btn-link,
fieldset[disabled] .btn-link:hover,
fieldset[disabled] .btn-link:focus,
fieldset[disabled] .btn-link:active,
fieldset[disabled] .btn-link.active {
     color: #cacaca;
}

.btn-white {
     color: inherit;
     background: white;
     border: 1px solid #e7eaec;
}

.btn-white:hover,
.btn-white:focus,
.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white,
.btn-white:active:focus,
.btn-white:active:hover,
.btn-white.active:hover,
.btn-white.active:focus {
     color: inherit;
     border: 1px solid #d2d2d2;
}

.btn-white:active,
.btn-white.active {
     -webkit-box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
     box-shadow: 0 2px 5px rgba(0, 0, 0, 0.15) inset;
}

.btn-white:active,
.btn-white.active,
.open .dropdown-toggle.btn-white {
     background-image: none;
}

.btn-white.disabled,
.btn-white.disabled:hover,
.btn-white.disabled:focus,
.btn-white.disabled:active,
.btn-white.disabled.active,
.btn-white[disabled],
.btn-white[disabled]:hover,
.btn-white[disabled]:focus,
.btn-white[disabled]:active,
.btn-white.active[disabled],
fieldset[disabled] .btn-white,
fieldset[disabled] .btn-white:hover,
fieldset[disabled] .btn-white:focus,
fieldset[disabled] .btn-white:active,
fieldset[disabled] .btn-white.active {
     color: #cacaca;
}

.landing-page .btn-primary.btn-outline:hover,
.landing-page .btn-success.btn-outline:hover,
.landing-page .btn-info.btn-outline:hover,
.landing-page .btn-warning.btn-outline:hover,
.landing-page .btn-danger.btn-outline:hover {
     color: #fff;
}

.landing-page .btn-primary {
     background-color: var(--fp_main_color);
     border-color: var(--fp_main_color);
     color: #ffffff;
     font-size: 14px;
     padding: 10px 20px;
     font-weight: 600;
}

.landing-page .btn-primary:hover,
.landing-page .btn-primary:focus,
.landing-page .btn-primary:active,
.landing-page .btn-primary.active,
.landing-page .open .dropdown-toggle.btn-primary {
     background-color: #179d82;
     border-color: #179d82;
     color: #ffffff;
}

.landing-page .btn-primary:active,
.landing-page .btn-primary.active,
.landing-page .open .dropdown-toggle.btn-primary {
     background-image: none;
}

.landing-page .btn-primary.disabled,
.landing-page .btn-primary.disabled:hover,
.landing-page .btn-primary.disabled:focus,
.landing-page .btn-primary.disabled:active,
.landing-page .btn-primary.disabled.active,
.landing-page .btn-primary[disabled],
.landing-page .btn-primary[disabled]:hover,
.landing-page .btn-primary[disabled]:focus,
.landing-page .btn-primary[disabled]:active,
.landing-page .btn-primary.active[disabled],
.landing-page fieldset[disabled] .btn-primary,
.landing-page fieldset[disabled] .btn-primary:hover,
.landing-page fieldset[disabled] .btn-primary:focus,
.landing-page fieldset[disabled] .btn-primary:active,
.landing-page fieldset[disabled] .btn-primary.active {
     background-color: #1dc5a3;
     border-color: #1dc5a3;
}

.form-control,
.form-control:focus,
.has-error .form-control:focus,
.has-success .form-control:focus,
.has-warning .form-control:focus,
.navbar-collapse,
.navbar-form,
.navbar-form-custom .form-control:focus,
.navbar-form-custom .form-control:hover,
.open .btn.dropdown-toggle,
.panel,
.popover,
.progress,
.progress-bar {
     -webkit-box-shadow: none;
     box-shadow: none;
}

.btn-rounded {
     border-radius: 50px;
}

.btn-large-dim {
     width: 90px;
     height: 90px;
     font-size: 42px;
}

button.dim {
     display: inline-block;
     color: #fff;
     text-decoration: none;
     text-transform: uppercase;
     text-align: center;
     padding-top: 6px;
     margin-right: 10px;
     position: relative;
     cursor: pointer;
     border-radius: var(--fp_main_border_radius, 6px);
     font-weight: 600;
     margin-bottom: 20px !important;
}

button.dim:active {
     top: 3px;
}

button.btn-primary.dim {
     -webkit-box-shadow: inset 0px 0px 0px #16987e, 0px 5px 0px 0px #16987e,
          0px 10px 5px #999999;
     box-shadow: inset 0px 0px 0px #16987e, 0px 5px 0px 0px #16987e,
          0px 10px 5px #999999;
}

button.btn-primary.dim:active {
     -webkit-box-shadow: inset 0px 0px 0px #16987e, 0px 2px 0px 0px #16987e,
          0px 5px 3px #999999;
     box-shadow: inset 0px 0px 0px #16987e, 0px 2px 0px 0px #16987e,
          0px 5px 3px #999999;
}

button.btn-default.dim {
     -webkit-box-shadow: inset 0px 0px 0px #b3b3b3, 0px 5px 0px 0px #b3b3b3,
          0px 10px 5px #999999;
     box-shadow: inset 0px 0px 0px #b3b3b3, 0px 5px 0px 0px #b3b3b3,
          0px 10px 5px #999999;
}

button.btn-default.dim:active {
     -webkit-box-shadow: inset 0px 0px 0px #b3b3b3, 0px 2px 0px 0px #b3b3b3,
          0px 5px 3px #999999;
     box-shadow: inset 0px 0px 0px #b3b3b3, 0px 2px 0px 0px #b3b3b3,
          0px 5px 3px #999999;
}

button.btn-warning.dim {
     -webkit-box-shadow: inset 0px 0px 0px #f79d3c, 0px 5px 0px 0px #f79d3c,
          0px 10px 5px #999999;
     box-shadow: inset 0px 0px 0px #f79d3c, 0px 5px 0px 0px #f79d3c,
          0px 10px 5px #999999;
}

button.btn-warning.dim:active {
     -webkit-box-shadow: inset 0px 0px 0px #f79d3c, 0px 2px 0px 0px #f79d3c,
          0px 5px 3px #999999;
     box-shadow: inset 0px 0px 0px #f79d3c, 0px 2px 0px 0px #f79d3c,
          0px 5px 3px #999999;
}

button.btn-info.dim {
     -webkit-box-shadow: inset 0px 0px 0px #1eacae, 0px 5px 0px 0px #1eacae,
          0px 10px 5px #999999;
     box-shadow: inset 0px 0px 0px #1eacae, 0px 5px 0px 0px #1eacae,
          0px 10px 5px #999999;
}

button.btn-info.dim:active {
     -webkit-box-shadow: inset 0px 0px 0px #1eacae, 0px 2px 0px 0px #1eacae,
          0px 5px 3px #999999;
     box-shadow: inset 0px 0px 0px #1eacae, 0px 2px 0px 0px #1eacae,
          0px 5px 3px #999999;
}

button.btn-success.dim {
     -webkit-box-shadow: inset 0px 0px 0px #1872ab, 0px 5px 0px 0px #1872ab,
          0px 10px 5px #999999;
     box-shadow: inset 0px 0px 0px #1872ab, 0px 5px 0px 0px #1872ab,
          0px 10px 5px #999999;
}

button.btn-success.dim:active {
     -webkit-box-shadow: inset 0px 0px 0px #1872ab, 0px 2px 0px 0px #1872ab,
          0px 5px 3px #999999;
     box-shadow: inset 0px 0px 0px #1872ab, 0px 2px 0px 0px #1872ab,
          0px 5px 3px #999999;
}

button.btn-danger.dim {
     -webkit-box-shadow: inset 0px 0px 0px #ea394c, 0px 5px 0px 0px #ea394c,
          0px 10px 5px #999999;
     box-shadow: inset 0px 0px 0px #ea394c, 0px 5px 0px 0px #ea394c,
          0px 10px 5px #999999;
}

button.btn-danger.dim:active {
     -webkit-box-shadow: inset 0px 0px 0px #ea394c, 0px 2px 0px 0px #ea394c,
          0px 5px 3px #999999;
     box-shadow: inset 0px 0px 0px #ea394c, 0px 2px 0px 0px #ea394c,
          0px 5px 3px #999999;
}

button.dim:before {
     font-size: 50px;
     line-height: 1em;
     font-weight: normal;
     color: #fff;
     display: block;
     padding-top: 10px;
}

button.dim:active:before {
     top: 7px;
     font-size: 50px;
}

/* CUSTOM  */
.fp-btn-new-group {
     background-color: #fff !important;
     font-weight: 600;
}

.fp-btn-new-group:hover {
     color: var(--fp_main_color);
}

.btn-outline {
     color: inherit;
     background-color: transparent;
     -webkit-transition: all 0.5s;
     transition: all 0.5s;
}

.btn.btn-outline-success {
     --bs-btn-color: var(--fp_main_color);
     --bs-btn-border-color: var(--fp_main_color);
     --bs-btn-hover-color: #fff;
     --bs-btn-hover-bg: var(--fp_main_color);
     --bs-btn-hover-border-color: var(--fp_main_color);
     --bs-btn-focus-shadow-rgb: 25, 135, 84;
     --bs-btn-active-color: #fff;
     --bs-btn-active-bg: var(--fp_main_color);
     --bs-btn-active-border-color: var(--fp_main_color);
     --bs-btn-active-shadow: inset 0 3px 5px rgba(0, 0, 0, 0.125);
     --bs-btn-disabled-color: var(--fp_main_color);
     --bs-btn-disabled-bg: transparent;
     --bs-btn-disabled-border-color: var(--fp_main_color);
     --bs-gradient: none;
}

.btn-outline-success.disabled,
.btn-outline-success:disabled {
     color: var(--fp_main_color);
}

.fp-btn-price-tree,
.btn-outline-main {
     background-color: #fff;
     font-weight: 600;
     margin-left: 5px;
     border-color: var(--fp_main_color);
     color: var(--fp_main_color);
     font-size: var(--fp_btn_xs_font_size, 0.75rem);
}

.btn-outline-main span {
     padding: 0;
}

.btn-outline-main i {
     font-size: 0.6rem;
}

.fp-btn-price-tree:hover:not(:disabled),
.fp-btn-price-tree:hover:not(:disabled) *,
.btn-outline-main:hover,
.btn-outline-main:not(:active):hover *,
.btn-outline-success:not(:disabled):not(.disabled).active,
.show>.btn-outline-success.dropdown-toggle {
     color: #fff !important;
     background-color: var(--fp_main_color);
     border-color: #8fccbf;
}

.btn-edit,
.btn-edit * {
     color: var(--fp_main_color, #16987e);
}

.btn-edit:hover,
.btn-edit:hover * {
     color: var(--fp_secondary_color, #1c83c4);
}

.fp-btn-price-tree:focus {
     box-shadow: unset;
}

.payments-buttons-wrapper {
     display: flex;
     justify-content: space-between;
}

.fp-mdl-btn-submit {
     border-radius: var(--fp_main_border_radius, 6px);
     cursor: pointer;
     width: 100%;
     margin: 0;
     text-transform: initial;
     padding: 14px 0;
     color: #ffffff;
     display: block;
     box-shadow: none;
     align-items: center;
     justify-content: center;
     text-align: center;
     border: none;
     -webkit-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
     -o-transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
     transition: all 0.4s cubic-bezier(0.165, 0.84, 0.44, 1);
     background: var(--fp_main_color, #1bc0a0);
     font-size: 16px;
     font-weight: 700;
     text-transform: uppercase;
     transition: all ease-in-out 0.7s;
     outline: unset !important;
}

.fp-mdl-btn-submit:hover {
     background: var(--fp_main_color, #1bc0a0);
}

.fp-content-search-wrap button {
     max-width: 230px;
     margin-top: 0.5rem;
}

/* SOCIAL BUTTONS START */

.fp-socials-btns-container {
     text-align: center;
}

.fp-mdl-social-btn {
     width: 100%;
     display: flex;
     gap: 10px;
     justify-content: center;
     align-items: center;
     background-color: #fff;
     border: 1px solid #dee0e2;
     border-radius: var(--fp_main_border_radius, 6px);
     margin: 10px 0 0;
     height: 45px;
     padding: 0;
     box-sizing: border-box;
     overflow: hidden;
     position: relative;
     cursor: pointer;
     -webkit-appearance: none !important;
     -moz-appearance: none !important;
     appearance: none !important;
     text-decoration: none;
     color: #121212;
     font-weight: 400;
     font-size: 12pt !important;
     transition: all ease-in-out 0.3s;
}

.fp-mdl-social-btn:hover .fp-mdl-social-btn-text {
     color: var(--fp_main_color);
}

.fp-mdl-social-btn-icon {
     flex: 0 0 35%;
     display: flex;
     justify-content: flex-end;
     align-items: center;
}

.fp-mdl-social-btn-text {
     flex: 1;
     display: flex;
     justify-content: flex-start;
     font-weight: 600;
     font-size: 0.9rem !important;
}

#fp-apple svg {
     height: 25px;
     width: 38px;
}

#fp-facebook svg {
     height: 22px;
     width: 41px;
}

#fp-facebook svg g {
     fill: #4267b2;
}

/* SOCIAL BUTTONS END */

.fp-action-btn {
     vertical-align: middle;
     opacity: 1;
     display: flex;
     gap: 5px;
     align-items: center;
     width: 100px;
     border-bottom: unset;
}

td.fp-action-btn.text-center {
     width: unset;
}

.fp-btn-delete {
     font-weight: 600;
     margin-left: 5px;
     font-size: var(--fp_btn_xs_font_size);
}

.fp-btn-delete:hover {
     background-color: #ffffff;
     color: red !important;
     font-weight: 600;
}

.fp-btn-warning:hover {
     background-color: #ffffff;
     color: #f8ac59 !important;
     font-weight: 600;
}

.btn-secondary.active {
     background-color: #2d8f7b;
     color: #ffffff;
}

.btn-primary:not([disabled]):not(.disabled).active,
.btn-primary:not([disabled]):not(.disabled):active,
.show>.btn-primary.dropdown-toggle {
     color: #fff;
     background-color: #18a689 !important;
     border-color: #18a689 !important;
}

body .fp-bg-btn {
     background-color: var(--fp_main_color) !important;
     color: white;
}

.close-btn {
     display: none;
     opacity: 0;
     -webkit-transition: all 500ms ease-in-out;
     transition: all 500ms ease-in-out;
}

.fp-close-btn-custom {
     cursor: pointer;
     font-size: var(--fp_btn_xs_font_size) !important;
     opacity: 0.7;
}

.fp-close-btn-custom:hover {
     opacity: unset;
}

.btn-group>.btn-group:not(:first-child)>.btn,
.btn-group>.btn:nth-child(n + 3),
.btn-group> :not(.btn-check)+.btn,
.btn-group>.btn-group:not(:last-child)>.btn,
.btn-group>.btn.dropdown-toggle-split:first-child,
.btn-group>.btn:not(:last-child):not(.dropdown-toggle) {
     border-radius: var(--fp_main_border_radius);
}

:not(.btn-check)+.btn-outline-main:active,
.btn-outline-main:active *,
.btn-outline-main:first-child:active {
     background-color: var(--fp_main_color);
     border-color: white;
     color: white;
}

.btn-group .btn {
     max-width: 170px;
}

@media screen and (max-width: 375px) {
     .fp-btn-price-tree {
          margin-left: 3px;
     }
}